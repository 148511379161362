import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocationInput from "../LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";


const moment = require("moment")

export interface StaySearchFormProps {
    searchParams?: {
        slugTipology: string;
        slugLocality: string;
        startDate: Date | null;
        endDate: Date | null;
        localityCity: string;
        localityForm: string;
    };
}

const StaySearchForm: FC<StaySearchFormProps> = ({ searchParams }) => {
    const navigate = useNavigate();

    const [location, setLocation] = useState(searchParams?.localityForm || "");
    const [dates, setDates] = useState<{ startDate: Date | null, endDate: Date | null }>({
        startDate: searchParams?.startDate || null,
        endDate: searchParams?.endDate || null,
    });

    useEffect(() => {
        if (searchParams) {
            setLocation(searchParams.slugLocality || "");
            setDates({ startDate: searchParams.startDate, endDate: searchParams.endDate });
        }
    }, [searchParams]);

    const isFormComplete = Boolean(location);

    const handleSearch = () => {

        // console.log("location2", location)

        let url = `/stabilimenti-balneari/${location.toLowerCase().replace(/\s+/g, '-')}`;

        // return;
        //
        if (isFormComplete) {
            const startDateStr = (dates.startDate) ? moment(dates.startDate).format("YYYY-MM-DD") : null;
            const endDateStr = (dates.endDate) ? moment(dates.endDate).format("YYYY-MM-DD") : ((startDateStr) ? startDateStr : null);

            if (startDateStr !== null || endDateStr !== null) {
                url += `?start_date=${startDateStr}&end_date=${endDateStr}`;
            }

            navigate(url);
            return;
        }

        if (dates.startDate && dates.endDate) {
            url += `?start_date=${moment(dates.startDate).format("YYYY-MM-DD")}&end_date=${moment(dates.endDate).format("YYYY-MM-DD")}`;
        }
        // console.log(url)

        // return;
        navigate(url);

    };

    useEffect(() => {
        // handleSearch();
    }, [location, dates]);


    const setLocation2 = (e) => {
        setLocation(e)
    }

    return (
        <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
            <LocationInput className="flex-[1.5]" setLocation={setLocation2} initialLocation={location} />
            <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
            <StayDatesRangeInput className="flex-1" setDates={setDates} handleSearch={handleSearch} isFormComplete={isFormComplete} initialDates={dates} />
        </form>
    );
};

export default StaySearchForm;
