import React, {FC, Fragment, useEffect, useState} from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Amenities_demos, PHOTOS} from "./constant";
import {Dialog, Transition} from "@headlessui/react";
import {ArrowRightIcon, Squares2X2Icon} from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import moment from "moment/moment";
import {DetailAPI, SearchAPI, StreamAPI} from "api/index";
import {Helmet} from "react-helmet";
import {ReactComponent} from "*.svg";
import {Skeleton} from "@mui/material";
import {Utils} from "@custom";
import ButtonThird from "../../../shared/Button/ButtonThird";
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";
import ListingImageGallery from "../../../components/ListingImageGallery/ListingImageGallery";
import MobileFooterSticky from "../(components)/MobileFooterSticky";

export interface Structure {
    allowed?: boolean;
    amenities: string[];
    city: string;
    description_profile?: string;
    items_free?: number;
    lat: number;
    lng: number;
    locality: string;
    name: string;
    slug: string;
    photos: string[];
    start_price?: number;
    genToken?: string;
}

const StayDetailPageContainer: FC<{ structure: Structure, titleSEO: string, location_slug: string }> = ({structure, titleSEO, location_slug}) => {


    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

    const [photos, setPhotos] = useState(structure.photos.map((p) => { return {id: null, url: null }}));

    useEffect(() => {
        const loadImages = () => {
            const imgs = [...photos];

            for (let i = 0; i < structure.photos.length; i++) {
                StreamAPI.streamImage(structure.photos[i]).then((resImg) => {
                    imgs[i] = { id: i, url: resImg };
                    setPhotos([...imgs]);
                });
            }
        };

        loadImages();
    }, [structure.photos]);

    const thisPathname = useLocation().pathname;
    const router = useNavigate();

    function closeModalAmenities() {
        setIsOpenModalAmenities(false);
    }

    function openModalAmenities() {
        setIsOpenModalAmenities(true);
    }

    const handleOpenModalImageGallery = () => {
        let params = new URLSearchParams(document.location.search);
        params.set("modal", "PHOTO_SCROLLABLE");
        navigate(`${thisPathname}?${params.toString()}`, {replace: true});
    };

    const handleCloseModalImageGallery = () => {
        let params = new URLSearchParams(document.location.search);
        params.delete("modal");
        navigate(`${thisPathname}?${params.toString()}`, {replace: true});
    };

    const renderSection1 = (structure) => {
        return (
            <div className="listingSection__wrap !space-y-6">

                {(structure.items_free) ? <div className="flex justify-between items-center">
                    {(structure.items_free > 0) ? ((structure.items_free <= 10) ? <Badge color="pink" name={"Solo " + structure.items_free + " " + ((structure.items_free === 1) ? "postazione rimasta" : "postazioni rimaste") + " a questo prezzo!"}/> : <Badge color="yellow" name="Poche postazioni disponibili!"/>) : <Badge color="pink" name={"Struttura al completo sul nostro sito!"}/>}
                    {/*<LikeSaveBtns />*/}
                </div> : null}

                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                    {structure.name}
                </h2>

                <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {structure.locality}</span>
          </span>
                </div>

                <div className="w-full border-b border-neutral-100 dark:border-neutral-700"/>

                <div
                    className="flex items-center xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                    {structure.amenities.slice(0, 3).map((amenity, i) => {
                        return <div key={i} className="flex items-center space-x-3 ">
                            <i className={amenity.icon_frontend + " text-2xl "}></i>
                            <span className="">
                                {amenity.label}
                            </span>
                        </div>
                    })}
                </div>
            </div>
        );
    };

    const renderSection2 = (description) => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Descrizione</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div
                    className="text-neutral-6000 dark:text-neutral-300"
                    dangerouslySetInnerHTML={{__html: description}}
                ></div>
            </div>
        );
    };

    const renderSection3 = (structure) => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Caratteristiche </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {`Scopri le caratteristiche della nostra struttura`}
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                    {structure.amenities.slice(0, 10).map((amenity, i) => {
                        return <div key={i} className="flex items-center space-x-3 ">
                            <i className={amenity.icon_frontend + " text-3xl "}></i>
                            <span className="">{amenity.label}</span>
                        </div>
                    })}
                </div>

                <div className="w-14 border-b border-neutral-200"></div>
                <div>
                    <ButtonSecondary onClick={openModalAmenities}>
                        Guarda tutte le Caratteristiche
                    </ButtonSecondary>
                </div>
                {renderMotalAmenities(structure)}
            </div>
        );
    };

    const renderMotalAmenities = (structure) => {
        return (
            <Transition appear show={isOpenModalAmenities} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalAmenities}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-screen w-full max-w-4xl">
                                <div
                                    className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                                    <div
                                        className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3
                                            className="text-lg font-medium leading-6 text-gray-900"
                                            id="headlessui-dialog-title-70"
                                        >
                                            Caratteristiche
                                        </h3>
                                        <span className="absolute left-3 top-3">
                                          <ButtonClose onClick={closeModalAmenities}/>
                                        </span>
                                    </div>
                                    <div
                                        className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                        {structure.amenities.map((amenity, i) => {
                                            return <div
                                                key={i}
                                                className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                                            >
                                                <i className={"text-4xl text-neutral-6000 " + amenity.icon_frontend}></i>
                                                <span className="">
                                {amenity.label}
                              </span>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };


    const renderSection7 = (structure) => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Posizione</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {structure.locality}
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

                {/* MAP */}
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                    <div className="rounded-xl overflow-hidden z-0">
                        <iframe
                            title="x"
                            width="100%"
                            height="100%"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={"https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_GOOGLE_SERVICES_KEY + "&q=" + structure.lat + " " + structure.lng}
                        ></iframe>
                    </div>
                </div>
            </div>
        );
    };

    const renderSection8 = (structure) => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <h2 className="text-2xl font-semibold">Informazioni Varie</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

                {/* CONTENT */}
                <div>
                    <h4 className="text-lg font-semibold">Termini di Cancellazione</h4>
                    <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br/>
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>


            </div>
        );
    };

    const renderSidebar = (structure) => {

        const params = new URLSearchParams(window.location.search);
        const queryString = params.toString();

        return (
            <div className="listingSectionSidebar__wrap shadow-xl">
                {(structure.start_price) ? <div className="flex flex-col">
                    <span className="text-base font-normal text-neutral-500">
                        a partire da
                    </span>
                    <span className="text-3xl font-semibold">
                        {Utils.getValueMoneyLabel(structure.start_price)}
                    </span>
                </div> : null}

                 {/*FORM*/}
                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <StayDatesRangeInput
                        className="flex-1 z-[11]"
                        startDateParams={structure.start_date}
                        endDateParams={structure.end_date}
                        location_slug={location_slug}
                        slug_structure={structure.slug} />
                </form>

                {/* SUM */}
                {/*<div className="flex flex-col space-y-4">*/}
                {/*    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">*/}
                {/*        <span>$119 x 3 night</span>*/}
                {/*        <span>$357</span>*/}
                {/*    </div>*/}
                {/*    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">*/}
                {/*        <span>Service charge</span>*/}
                {/*        <span>$0</span>*/}
                {/*    </div>*/}
                {/*    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>*/}
                {/*    <div className="flex justify-between font-semibold">*/}
                {/*        <span>Total</span>*/}
                {/*        <span>$199</span>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* SUBMIT */}

                {(structure.allowed && structure.start_date && structure.end_date) ? <ButtonPrimary disabled href={"/booking/" + structure.genToken + "?" + queryString}>Scegli Postazione</ButtonPrimary> : null}

                {/*{(!structure.allowed) ? <ButtonSecondary disabled href={"/booking/" + structure.genToken}>*/}
                {/*    <PaperAirplaneIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />*/}
                {/*    <span className="ml-3">Richiedi Prenotazione</span>*/}
                {/*</ButtonSecondary> : <ButtonPrimary href={"/booking/" + structure.genToken}>Scegli Postazione</ButtonPrimary>}*/}
            </div>
        );
    };

    const renderHeader = (structure, modal, navigate) => {

        // let imgs = [];
        //
        // for (let i = 0; i < structure.photos.length; i++) {
        //     StreamAPI.streamImage(structure.photos[i]).then((resImg) => {
        //         imgs.push({id: i, url: resImg})
        //         setPhotos(imgs)
        //     })
        // }
        //
        // resDetail["photos"] = imgs;

        return (
            <header className="rounded-md sm:rounded-xl">
                {(photos.length > 0) ? <ListingImageGallery
                    isShowModal={modal === "PHOTO_SCROLLABLE"}
                    onClose={handleCloseModalImageGallery}
                    images={photos}
                /> : null}
                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                    <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                        onClick={handleOpenModalImageGallery}
                    >
                        {(photos[0]?.url) ? <img
                            className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                            src={photos[0].url}
                            alt=""
                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                        /> : <Skeleton variant="rectangular" height="100%" width="100%"/>}
                        <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                    </div>
                    {photos.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                            key={index}
                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                index >= 3 ? "hidden sm:block" : ""
                            }`}
                        >
                            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                                {(item.url) ? <img
                                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                                    src={item.url}
                                    alt=""
                                    sizes="400px"
                                /> : <Skeleton variant="rectangular" height="100%" width="100%"/>}
                            </div>

                             {/*OVERLAY*/}
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={handleOpenModalImageGallery}
                            />
                        </div>
                    ))}

                    {(photos.length > 0) ? <button
                        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                        onClick={handleOpenModalImageGallery}
                    >
                        <Squares2X2Icon className="w-5 h-5"/>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                          Guarda tutte le foto
                        </span>
                    </button> : null}
                </div>
            </header>
        );
    };

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const modal = searchParams?.get("modal");

    return (
        <div className="nc-ListingStayDetailPage">
            <Helmet>
                <title>{titleSEO} - Easy Beach</title>
                <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
            </Helmet>
            {/*  HEADER */}

            {(structure.photos.length > 0) ? renderHeader(structure, modal, navigate) : null}

            {/* MAIN */}
            <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
                {/* CONTENT */}
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                    {renderSection1(structure)}
                    {renderSection2(structure.description_profile)}
                    {renderSection3(structure)}
                    {renderSection7(structure)}
                    {/*{renderSection8(structure)}*/}
                </div>

                {/* SIDEBAR */}
                {(structure.allowed) ? <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                    <div className="sticky top-28">{renderSidebar(structure)}</div>
                </div> : null}

                <MobileFooterSticky structure={structure}/>
            </main>
        </div>
    );
};

const StayDetailPageLoader: FC<{}> = () => {


    return (
        <div className="nc-ListingStayDetailPage">

            <header className="rounded-md sm:rounded-xl">
                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                    <div
                        className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden">
                        <Skeleton variant="rectangular" height="100%" width="100%"/>
                    </div>
                    {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                        <div
                            key={index}
                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                                index >= 3 ? "hidden sm:block" : ""
                            }`}
                        >
                            <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                                <Skeleton variant="rectangular" height="100%" width="100%"/>
                            </div>
                        </div>
                    ))}
                </div>
            </header>

            <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">

                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">

                    <div className="listingSection__wrap !space-y-6">
                        <Skeleton variant="rectangular" height="30px" width="100%"/>
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/>
                        <Skeleton variant="rectangular" height="30px" width="100%"/>
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                    </div>

                    <div className="listingSection__wrap">
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                        <Skeleton variant="rectangular" height="500px" width="100%"/>
                    </div>

                    <div className="listingSection__wrap !space-y-6">
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <Skeleton variant="rectangular" height="40px" width="100%"/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                    </div>

                    <div className="listingSection__wrap !space-y-6">
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <Skeleton variant="rectangular" height="40px" width="100%"/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="20px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                    </div>

                    <div className="listingSection__wrap !space-y-6">
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <Skeleton variant="rectangular" height="500px" width="100%"/>
                    </div>

                    <div className="listingSection__wrap !space-y-6">
                        <Skeleton variant="rectangular" height="50px" width="100%"/>
                        <Skeleton variant="rectangular" height="300px" width="100%"/>
                    </div>
                </div>

                <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                    <div className="listingSectionSidebar__wrap shadow-xl">
                        <Skeleton variant="rectangular" height="60px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="100px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="40px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="30px" width="100%"/><br/>
                        <Skeleton variant="rectangular" height="40px" width="100%"/>
                    </div>
                </div>
            </main>
        </div>
    );

}

export default function ListingStayDetailPage() {

    const location = useLocation();
    const navigate = useNavigate();
    const {tipology, locality, structure} = useParams();

    const [searchParams, setSearchParams] = useState(null);
    const [titleSEO, setTitleSEO] = useState("");

    const [genToken, setGenToken] = useState(null);

    const [structure_detail, setStructureDetail] = useState<Structure | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const startDate = params.get("start_date");
        const endDate = params.get("end_date");
        const queryLocality = params.get("q");

        if (startDate !== null || endDate !== null) {
            if (!moment(startDate, "YYYY-MM-DD").isValid() || !moment(endDate, "YYYY-MM-DD").isValid()) {
                navigate(`/stabilimenti-balneari/${locality}/${structure}}`, {replace: true});
                return;
            }

            if (moment(startDate, "YYYY-MM-DD").isBefore(moment(), "day")) {
                navigate(`/stabilimenti-balneari/${locality}/${structure}`, {replace: true});
                return;
            }
        }

        let genToken = sessionStorage.getItem("genToken")

        if (structure_detail) {
            if (structure_detail.slug === structure && startDate === searchParams?.startDate && endDate === searchParams?.endDate) {
                return;
            }
        }
        setStructureDetail(null);

        DetailAPI.detailStructure(tipology, locality, structure, startDate, endDate, genToken).then(async (resDetail) => {

            setSearchParams({
              startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
              endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null
            });

            if (resDetail.genToken)
                sessionStorage.setItem("genToken", resDetail.genToken)
            else
                sessionStorage.removeItem("genToken")

            setTitleSEO(((tipology === "stabilimenti-balneari") ? "Stabilimento Balneare " : "") + resDetail.name + " " + resDetail.city)

            setStructureDetail(resDetail);

        }).catch((e) => {
            navigate(`/stabilimenti-balneari/${locality}`, {replace: true});
        });

    }, [location.search, tipology, locality, navigate, structure]);

    return (
        <DetailPagetLayout>
            {(structure_detail !== null) ? <StayDetailPageContainer titleSEO={titleSEO} structure={structure_detail} location_slug={locality}/> :
                <StayDetailPageLoader/>}
        </DetailPagetLayout>
    );
}
