import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {Utils} from "custom/index";
import ButtonSecondary from "../../../shared/Button/ButtonSecondary";
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";
import {useLocation, useNavigate} from "react-router-dom";

const moment = require("moment");

const MobileFooterSticky = ({structure}) => {

    const navigate = useNavigate();
    const location = useLocation();

    let handleClick = () => {
        const button = document.getElementById("open-data-modal");
        if (button)
            button.click();
    }

    const handleChangeData = (dates) => {
        if (dates.startDate && dates.endDate) {
            const queryParams = new URLSearchParams(location.search);

            queryParams.set('start_date', moment(dates.startDate).format('YYYY-MM-DD'));
            queryParams.set('end_date', moment(dates.endDate).format('YYYY-MM-DD'));

            const newUrl = `${location.pathname}?${queryParams.toString()}`;

            navigate(newUrl, { replace: true });
        }
    };

    const params = new URLSearchParams(window.location.search);
    const queryString = params.toString();

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
            {(structure.start_date && structure.end_date && structure.start_price > 0) ? <span className="block text-lg font-semibold">
                <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                    A partire da
                </span>
                &nbsp;
                {Utils.getValueMoneyLabel(structure.start_price)}
            </span> : null}
            {(structure.start_date && structure.end_date) ? (
                <ModalSelectDate
                    dates={{startDate: structure.start_date, endDate: structure.end_date}}
                    onDateChange={handleChangeData}
                    renderChildren={({ openModal }) => (
                        <span
                            onClick={openModal}
                            className="block text-sm underline font-medium"
                        >
                            {(structure.start_date === structure.end_date) ? moment(structure.start_date, "YYYY-MM-DD").format("ddd DD MMM YYYY") : converSelectedDateToString([
                                new Date(moment(structure.start_date, "YYYY-MM-DD").format("YYYY/MM/DD")),
                                new Date(moment(structure.end_date, "YYYY-MM-DD").format("YYYY/MM/DD"))
                            ])}
                        </span>
                    )}
                />
            ) : <ModalSelectDate
                dates={{startDate: null, endDate: null}}
                onDateChange={handleChangeData}
                renderChildren={({ openModal }) => (
                    <ButtonPrimary onClick={openModal}>Seleziona Date</ButtonPrimary>
                )}
            />}
        </div>
          {(structure.allowed && structure.start_date && structure.end_date) ? <ButtonPrimary href={"/booking/" + structure.genToken + "?" + queryString}>Scegli Postazione</ButtonPrimary> : null}
        {/*{(!structure.allowed) ? <ButtonSecondary href={"/booking/" + structure.genToken}>*/}
        {/*  <PaperAirplaneIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />*/}
        {/*  <span className="ml-3">Richiedi Prenotazione</span>*/}
        {/*</ButtonSecondary> : <ButtonPrimary href={"/booking/" + structure.genToken}>Scegli Postazione</ButtonPrimary>}*/}
      </div>
    </div>
  );
};

export default MobileFooterSticky;
