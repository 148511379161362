import React from 'react';

const TermsPage: React.FC = () => {

    require("./TermsPage.css")
    return (
        <div className="terms-container">
            <h1>Termini e Condizioni di Easy Beach</h1>

            <h2>1. Introduzione</h2>
            <p>
                Benvenuto su Easy Beach, una piattaforma di prenotazione online per stabilimenti balneari. Utilizzando la nostra piattaforma, accetti di rispettare e di essere vincolato dai seguenti termini e condizioni. Se non accetti questi termini, ti preghiamo di non utilizzare la nostra piattaforma.
            </p>

            <h2>2. Informazioni Generali</h2>
            <p><strong>Nome della piattaforma:</strong> Easy Beach</p>
            <p><strong>Proprietario:</strong> FB Web Studio</p>
            <p><strong>Partita IVA:</strong> IT02322060688</p>
            <p><strong>Indirizzo legale:</strong> Via Campo Felice, 16, Pescara, Italia</p>
            <p><strong>Contatti per assistenza clienti:</strong> support@easybeach.cloud <br /> +39 380 1978566</p>

            <h2>3. Utilizzo della Piattaforma</h2>
            <p><strong>Restrizioni d'età:</strong> Per prenotare sulla piattaforma è necessario essere maggiorenni.</p>
            <p><strong>Requisiti di registrazione:</strong> Per effettuare una prenotazione, l'utente deve fornire nome, cognome, numero di telefono e email.</p>

            <h2>4. Processo di Prenotazione</h2>
            <p>
                L'utente seleziona lo stabilimento balneare desiderato tramite ricerca per località o tramite link diretto fornito dalla struttura associata.
                Dopo aver selezionato la struttura, l'utente sceglie la propria postazione sulla mappa e può aggiungere eventuali optional come lettini o sdraio, che potrebbero comportare un costo aggiuntivo.
                L'utente compila i propri dati personali, seleziona il metodo di pagamento desiderato e completa la procedura di prenotazione online.
            </p>
            <p><strong>Metodi di pagamento accettati:</strong> Carta di credito/debito, Klarna (se disponibile), PayPal, pagamento in contanti o con carta tramite POS in struttura. Per il pagamento in struttura, è comunque richiesto l'inserimento di una carta di credito/debito per garantire la prenotazione.</p>

            <h2>5. Politica di Cancellazione</h2>
            <p>
                La politica di cancellazione è specificata in fase di checkout e gestita direttamente dalla struttura che può avvalersi di politiche di cancellazione entro un certo numero di giorni dalla data di prenotazione. Easy Beach non è responsabile per cancellazioni gestite dalle strutture.
            </p>

            <h2>6. Prezzi e Pagamenti</h2>
            <p><strong>Prezzi delle prenotazioni:</strong> I prezzi delle prenotazioni e delle tariffe delle postazioni sono decisi direttamente dalla struttura e includono IVA e imposte.</p>
            <p>
                <strong>Costi aggiuntivi:</strong> In alcuni casi, potrebbero essere addebitati costi di commissione aggiuntivi, denominati "Costi di servizio", tali costi servono per mantenere la piattaforma attiva e offrire un'esperienza positiva all'utente. Parte di tali introiti sarà destinata a finalità di beneficenza per la pulizia dei mari e delle spiagge.
            </p>

            <h2>7. Responsabilità</h2>
            <p><strong>Responsabilità di Easy Beach:</strong> Easy Beach garantisce la prenotazione tramite la piattaforma, ma la garanzia di soggiorno è gestita direttamente dalla struttura. Easy Beach non è responsabile per eventuali disservizi o problemi con le strutture.</p>
            <p><strong>Responsabilità degli utenti:</strong> Gli utenti devono fornire email e numero di telefono reali per garantire la prenotazione. Easy Beach e la struttura partner non saranno responsabili per il mancato recapito della conferma di prenotazione in caso di errata digitazione dei dati di contatto.</p>
            <p><strong>Rimborsi:</strong> In caso di problemi tecnici legati alla piattaforma, Easy Beach offre assistenza e rimborsi diretti ai clienti. Tali rimborsi possono essere gestiti anche direttamente dalla struttura.</p>

            <h2>8. Privacy e Sicurezza</h2>
            <p><strong>Gestione dei dati personali:</strong> I dati degli utenti sono archiviati in sicurezza e crittografati all'interno della piattaforma e non saranno rivenduti a terzi. Solo Easy Beach e la struttura convenzionata possono visualizzare i dati condivisi dagli utenti.</p>
            <p><strong>Misure di sicurezza:</strong> I dati sono crittografati durante la trasmissione e archiviati in modo sicuro sulla piattaforma in cloud.</p>

            <h2>9. Condizioni Generali</h2>
            <p><strong>Regole di comportamento:</strong> Gli utenti sono responsabili del proprio comportamento sulla piattaforma e non possono utilizzarla per creare danno alla struttura o alla piattaforma stessa.</p>
            <p><strong>Gestione dei reclami e delle controversie:</strong> I reclami e le controversie sono gestiti direttamente da Easy Beach o dalla struttura associata. Easy Beach si riserva il diritto di rifiutare richieste di rimborso non conformi alla politica di cancellazione.</p>
            <p><strong>Assistenza clienti:</strong> Gli utenti possono contattare direttamente la struttura in caso di problemi o l'assistenza clienti di Easy Beach tramite email o telefono.</p>

            <h2>10. Modifiche ai Termini e Condizioni</h2>
            <p>
                <strong>Comunicazione delle modifiche:</strong> Gli utenti non saranno direttamente avvisati delle modifiche ai termini e condizioni. È responsabilità dell'utente controllare periodicamente la pagina dei termini e condizioni per aggiornarsi su eventuali modifiche.
            </p>
            <p><strong>Accettazione delle modifiche:</strong> Gli utenti accettano i termini e condizioni al momento della conferma dell'ordine, senza possibilità di rivalsa.</p>

            <h2>11. Conformità al GDPR</h2>
            <h3>11.1 Responsabile del Trattamento dei Dati</h3>
            <p>Il responsabile del trattamento dei dati personali raccolti tramite la piattaforma Easy Beach è FB Web Studio, con sede legale in Via Campo Felice, 16, Pescara, Italia. Puoi contattare il nostro responsabile della protezione dei dati all'indirizzo support@easybeach.cloud.</p>

            <h3>11.2 Finalità del Trattamento dei Dati</h3>
            <p>I dati personali degli utenti vengono raccolti e trattati per le seguenti finalità:</p>
            <ul>
                <li>Gestione delle prenotazioni presso gli stabilimenti balneari</li>
                <li>Comunicazioni relative alle prenotazioni</li>
                <li>Miglioramento dei nostri servizi</li>
                <li>Adempimento degli obblighi legali e fiscali</li>
            </ul>

            <h3>11.3 Base Giuridica del Trattamento dei Dati</h3>
            <p>Il trattamento dei dati personali degli utenti si basa sulle seguenti basi giuridiche:</p>
            <ul>
                <li>Esecuzione di un contratto: per gestire le prenotazioni effettuate tramite la piattaforma</li>
                <li>Consenso: per l'invio di comunicazioni di marketing (se l'utente ha fornito il consenso)</li>
                <li>Adempimento di obblighi legali: per conformarsi alle normative fiscali e contabili</li>
            </ul>

            <h3>11.4 Diritti degli Utenti</h3>
            <p>In conformità con il GDPR, gli utenti hanno i seguenti diritti:</p>
            <ul>
                <li>Diritto di accesso: gli utenti possono richiedere una copia dei propri dati personali in nostro possesso</li>
                <li>Diritto di rettifica: gli utenti possono richiedere la correzione di dati personali inesatti o incompleti</li>
                <li>Diritto alla cancellazione: gli utenti possono richiedere la cancellazione dei propri dati personali</li>
                <li>Diritto alla limitazione del trattamento: gli utenti possono richiedere la limitazione del trattamento dei propri dati in determinate circostanze</li>
                <li>Diritto alla portabilità dei dati: gli utenti possono richiedere di ricevere i propri dati personali in un formato strutturato e leggibile da dispositivo automatico</li>
                <li>Diritto di opposizione: gli utenti possono opporsi al trattamento dei propri dati personali per motivi legittimi</li>
            </ul>

            <h3>11.5 Condivisione dei Dati con Terze Parti</h3>
            <p>
                I dati personali degli utenti non saranno venduti a terzi. Tuttavia, i dati possono essere condivisi con i seguenti soggetti:
            </p>
            <ul>
                <li>Strutture e attività partner, per la gestione delle prenotazioni</li>
                <li>Fornitori di servizi di pagamento (es. Stripe) per la gestione delle transazioni</li>
                <li>Autorità competenti, per adempiere agli obblighi legali</li>
            </ul>

            <h3>11.6 Periodo di Conservazione dei Dati</h3>
            <p>I dati personali degli utenti saranno conservati per il periodo necessario a soddisfare le finalità per cui sono stati raccolti, salvo diverse disposizioni legali.</p>

            <h3>11.7 Sicurezza dei Dati</h3>
            <p>Adottiamo misure tecniche e organizzative adeguate per proteggere i dati personali degli utenti da accessi non autorizzati, perdita, distruzione o divulgazione illecita. I dati sono crittografati durante la trasmissione e l'archiviazione.</p>

            <h3>11.8 Modifiche alla Politica sulla Privacy</h3>
            <p>Qualsiasi modifica alla nostra politica sulla privacy sarà comunicata agli utenti attraverso aggiornamenti su questa pagina. È responsabilità dell'utente verificare periodicamente eventuali aggiornamenti.</p>

            <h2>12. Pagamenti Rateizzati con Klarna</h2>
            <h3>12.1 Opzioni di Pagamento Rateizzato</h3>
            <p>Easy Beach offre ai clienti la possibilità di scegliere il pagamento rateizzato tramite Klarna, se disponibile. Questa opzione consente di suddividere il pagamento in tre rate mensili senza interessi.</p>

            <h3>12.2 Condizioni per il Pagamento Rateizzato</h3>
            <p>L'opzione di pagamento rateizzato è disponibile solo per determinati importi e potrebbe non essere disponibile per tutte le prenotazioni. Le condizioni specifiche per il pagamento rateizzato saranno indicate al momento del checkout.</p>

            <h3>12.3 Gestione dei Pagamenti Rateizzati</h3>
            <p>
                I pagamenti rateizzati sono gestiti direttamente da Klarna. Utilizzando questa opzione, l'utente accetta i termini e condizioni di Klarna, che saranno forniti durante il processo di pagamento.
                Per ulteriori informazioni sulle politiche di Klarna, inclusi i termini di servizio e l'informativa sulla privacy, si prega di visitare il sito web di Klarna all'indirizzo <a href="https://www.klarna.com">https://www.klarna.com</a>.
            </p>

            <h3>12.4 Responsabilità di Klarna</h3>
            <p>
                Easy Beach non è responsabile per la gestione dei pagamenti rateizzati tramite Klarna. Qualsiasi problema relativo ai pagamenti rateizzati, inclusi ritardi o mancati pagamenti, deve essere risolto direttamente con Klarna.
                In caso di domande o problemi relativi ai pagamenti rateizzati, gli utenti possono contattare l'assistenza clienti di Klarna tramite i canali forniti sul loro sito web.
            </p>

            <h3>12.5 Politica di Cancellazione e Rimborsi</h3>
            <p>
                In caso di cancellazione di una prenotazione effettuata con pagamento rateizzato tramite Klarna, la politica di cancellazione di Easy Beach si applica come descritto nella sezione 5. Qualsiasi rimborso sarà elaborato in conformità con le politiche di Klarna.
                Per ulteriori dettagli su come Klarna gestisce i rimborsi, si prega di consultare i termini di servizio di Klarna.
            </p>
            <br />
            <small>Ultimo aggiornamento:<br/>luglio 2024</small>
        </div>
    );
};

export default TermsPage;
