"use client";

import React, { FC, useState } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
// import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
// import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
// import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab = "Beach" | "Experiences" | "Boat";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Beach" | "Experiences" | "Boat";
}



const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Beach",
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Beach", "Experiences", "Boat"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);


    const renderLabel = (value: string) => {
        switch (value) {
            case "Beach":
                return <span>Spiagge</span>;
            case "Experiences":
                return <span style={{opacity: 0.5}}>Esperienze<br /><small className="text-red-500">Coming soon</small></span>;
            case "Boat":
                return <span style={{opacity: 0.5}}>Noleggio Barche<br /><small className="text-red-500">Coming soon</small></span>;

            default:
                return null;
        }
    };

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => (tab === null) ? setTabActive(tab) : {}}
              className={`flex-shrink-0 flex items-center text-sm lg:text-base cursor-pointer font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } ` + ((tab === null) ? 'cursor-pointer' : '')}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{renderLabel(tab)}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Beach":
        return <StaySearchForm searchParams={null} />;
      case "Experiences":
        return null;
      case "Boat":
        return null;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
