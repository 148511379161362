import React, {useEffect, useState} from "react";
import MyRouter from "routers/index";

import {Loading, Utils} from "custom"
import {checkVers} from "./CheckBuildService";

import NcImage from "./shared/NcImage/NcImage";
import IErrPng from "./images/error.png";
import ButtonPrimary from "./shared/Button/ButtonPrimary";
import Button from "./shared/Button/Button";
import logo from "./images/logo.png";
import {AuthAPI, BookingAPI} from "api/index";

import ReactPixel from 'react-facebook-pixel';

import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

import Hotjar from '@hotjar/browser';

global.checkAuth = async function (status) {
    if (status === "UNAUTHORIZED") {
        try {
            await AuthAPI.logout();
        } catch(e) {
            localStorage.removeItem('hash');
            sessionStorage.removeItem("userData");
            global.userData = undefined;
            window.location.replace('/');
        }
    }
};

global.getMetaTrask = function () {
    const getCookieValue = (name: string) => {
        const value = document.cookie
            .split(';')
            .filter(c => c.includes(`${name}=`))
            .map(c => c.split(`${name}=`)[1]);
        return (value.length && value[0]) || null;
    };

    let fbp = getCookieValue('_fbp');
    let fbc = getCookieValue('_fbc');

    if (!fbc && window.location.search.includes('fbclid=')) {
        fbc = `fb.1.${+new Date()}.${window.location.search.split('fbclid=')[1]}`;
    }

    if (fbp === null && fbc === null)
        return false;

    return {
        fbp: fbp,
        fbclid: fbc,
        url: `${window.location.origin}${window.location.pathname}`,
    };

}

function App() {

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);

    useEffect(() => {
        checkVers().then(() => {
            Utils.checkConnection().then(async () => {

                var jsonObject = global.getMetaTrask();

                console.log("jsonObject", jsonObject)

                BookingAPI.signVisit(jsonObject).then(() => {
                    console.log("VISIT SIGNED")
                }).catch((err) => {
                    console.error(err)
                })

                setLoading(false);
    //             // if (process.env.REACT_APP_NODE_ENV === "production")
    //             // Hotjar.init(siteId, hotjarVersion);
            }).catch((err) => {
                console.error(err)
                setLoading(false);
                setError(true)
            })
        }).catch((err) => {
            console.error(err)
            window.location.reload();
        })
    }, []);

    const handleAccept = () => {
        if (process.env.NODE_ENV === "production") {
            const siteId = process.env.REACT_APP_SITE_ID_HOTJAR;
            const hotjarVersion = 6;

            Hotjar.init(Number(siteId), hotjarVersion);

            const optionsPix = {
                autoConfig: true,
                debug: false,
            };
            // @ts-ignore
            ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, optionsPix);
            ReactPixel.pageView();
        }
    };


    if (loading) {
        return <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <Loading />
        </div>;
    }

    if (error) {
        return <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                <header className="text-center max-w-2xl mx-auto space-y-2 pt-10">
                    <NcImage src={IErrPng} />
                    <span className="block text-lg text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-semibold">
                      Stiamo Facendo il Bagno al Codice!
                    </span>
                     <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                      La piattaforma è in aggiornamento per offrirti un'esperienza ancora migliore. Torna presto e scopri tutte le novità! 🏖️🚀
                    </span>
                    <div className="pt-8">
                        <Button className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`} onClick={() => window.location.reload()}>
                            Riprova
                        </Button>
                    </div>
                </header>
            </div>
        </div>;
    }

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
        <CookieConsent
            location="bottom"
            buttonText="Accetta"
            declineButtonText="Rifiuta"
            cookieName="cookieConsent"
            style={{ background: "#fafafa", color: "#0a0a0a" }}
            buttonStyle={{ background: "#257BEB", color: "#fff", fontSize: "13px" }}
            declineButtonStyle={{ color: "#fff", background: "#257BEB", fontSize: "13px" }}
            expires={150}
            onAccept={handleAccept}
            enableDeclineButton
        >
            Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per le finalità di funzionalità, esperienza, misurazione e marketing (con annunci personalizzati) come specificato nella cookie policy.
            Usa il pulsante “Accetta” per acconsentire. Usa il pulsante “Rifiuta” per continuare senza accettare.
            Leggi la nostra <span style={{ fontSize: "14px" }}><a href="/cookie-policy">Cookie Policy</a></span>.
        </CookieConsent>
    </div>
  );
}

export default App;
