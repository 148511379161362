import DatePicker from "react-datepicker";
import React, { FC } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import moment from "moment/moment";

export interface StayDatesRangeInputProps {
    className?: string;
    value: { startDate: Date | null; endDate: Date | null };
    onChange: (dates: { startDate: Date | null; endDate: Date | null }) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
                                                               className = "",
                                                               value,
                                                               onChange,
                                                           }) => {
    const { startDate, endDate } = value;

    const onChangeDate = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        onChange({ startDate: start, endDate: end });
    };

    return (
        <div>
            <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {`Quando?`}
        </span>
            </div>
            <div
                className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className}`}
            >
                <DatePicker
                    locale="it"
                    selected={startDate}
                    onChange={(dates) => onChangeDate(dates as [Date | null, Date | null])}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={moment().toDate()}
                    maxDate={moment().endOf("year").toDate()}
                    selectsRange
                    monthsShown={2}
                    showPopperArrow={false}
                    inline
                    renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                    )}
                />
            </div>
        </div>
    );
};

export default StayDatesRangeInput;
