import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, {Fragment, useEffect, useState} from "react";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "./DatePickerCustomDay";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {PencilSquareIcon} from "@heroicons/react/24/outline";

const moment = require("moment")

const ModalSelectDate = ({ renderChildren, dates, onDateChange }) => {
  const [showModal, setShowModal] = useState(false);

  const [selectedDates, setSelectedDates] = useState([new Date(moment().add(1, "days").format("YYYY/MM/DD")), new Date(moment().add(1, "days").format("YYYY/MM/DD"))]);

  useEffect(() => {
    if (dates.startDate && dates.endDate)
      setSelectedDates([new Date(moment(dates.startDate).format("YYYY/MM/DD")), new Date(moment(dates.endDate).format("YYYY/MM/DD"))])
  }, [])

  const handleApply = () => {
    setShowModal(false);
    if (selectedDates[0] && selectedDates[1]) {
      onDateChange({startDate: selectedDates[0], endDate: selectedDates[1]})
    } else if (selectedDates[0] && !selectedDates[1]) {
      onDateChange({startDate: selectedDates[0], endDate: selectedDates[0]})
    }
  };

  const handleReset = () => {
    setShowModal(false);
    if (dates.startDate && dates.endDate)
      setSelectedDates([new Date(moment(dates.startDate).format("YYYY/MM/DD")), new Date(moment(dates.endDate).format("YYYY/MM/DD"))])
  };

  const openModal = () => {
    setShowModal(true);
  };

  const renderButtonOpenModal = () => {
    return renderChildren ? (
        renderChildren({ openModal })
    ) : (
        <button onClick={openModal}>Seleziona le date</button>
    );
  };

  return (
      <>
        {renderButtonOpenModal()}
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
              as="div"
              className="HeroSearchFormMobile__Dialog relative z-50"
              onClose={handleReset}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out transition-transform"
                    enterFrom="opacity-0 translate-y-52"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in transition-transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                    <>
                      <div className="absolute left-4 top-4">
                        <button
                            className="focus:outline-none focus:ring-0"
                            onClick={() => setShowModal(false)}
                        >
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                        <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800">
                          <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                            <div className="p-5">
                            <span className="block font-semibold text-xl sm:text-2xl">
                              {`Quando vuoi andare?`}
                            </span>
                            </div>
                            <div className="flex-1 relative flex z-10">
                              <div className="overflow-hidden rounded-3xl">
                                <DatePicker
                                    locale="it"
                                    selected={selectedDates[0]}
                                    onChange={(dates) => setSelectedDates(dates as [Date | null, Date | null])}
                                    startDate={selectedDates[0]}
                                    endDate={selectedDates[1]}
                                    minDate={moment().toDate()}
                                    maxDate={moment().endOf("year").toDate()}
                                    selectsRange
                                    monthsShown={2}
                                    showPopperArrow={false}
                                    inline
                                    renderCustomHeader={props => (
                                        <DatePickerCustomHeaderTwoMonth {...props} />
                                    )}
                                    renderDayContents={(day, date) => (
                                        <DatePickerCustomDay
                                            dayOfMonth={day}
                                            date={date}
                                        />
                                    )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                            type="button"
                            className="underline font-semibold flex-shrink-0"
                            onClick={handleReset}
                        >
                          Chiudi
                        </button>
                        <ButtonPrimary
                            sizeClass="px-6 py-3 !rounded-xl"
                            onClick={handleApply}
                            disabled={(!selectedDates[0])}
                        >
                          Applica
                        </ButtonPrimary>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
  );
};

export default ModalSelectDate;
