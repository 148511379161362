import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

const Loading = ({ loadingText = "Caricamento..." }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                position: 'fixed',
                top: 0,
                left: 0,
            }}
        >
            <CircularProgress />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
                {loadingText}
            </Typography>
        </Box>
    );
};

export default Loading;
