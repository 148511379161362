import React, {FC, Fragment, useEffect, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import {useLocation} from "react-router-dom";

interface ModalReserveMobileProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  formOrder?: any;
  setFormOrder?: any;
  selectedItemForm?: any;
  beachResort?: any;
  closeCheckout?: any;
}

const ModalReserveMobile: FC<ModalReserveMobileProps> = ({
  renderChildren,
   formOrder,
   setFormOrder,
   selectedItemForm,
   beachResort,
   closeCheckout
}) => {
  const [showModal, setShowModal] = useState(false);


  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const status = query.get('status');

  useEffect(() => {
    if (status === "false")
      setShowModal(true)
  }, [])


  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Seleziona Date</button>
    );
  };

  const setRevert = (status) => {
    closeCheckout(status)
    closeModal()
  }

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full flex-1 flex flex-col justify-between overflow-auto">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 py-1 flex flex-col ">
                      <div className="flex-1 bg-white dark:bg-neutral-900">
                        <CheckOutPage
                            formOrder={formOrder}
                            setFormOrder={setFormOrder}
                            selectedItemForm={selectedItemForm}
                            beachResort={beachResort}
                            setRevert={setRevert}
                        />
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalReserveMobile;
