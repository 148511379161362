let streamImage = function(hashView) {
    return new Promise(function(resolve, reject) {
        fetch(global.route.streamImage + hashView, {
            method: 'GET'
        })
            .then((response) => {
                if (response.status !== 500) {
                    return response.blob();
                } else {
                    throw new Error('Server response with status 500');
                }
            })
            .then((blob) => {
                let url = URL.createObjectURL(blob);
                resolve(url);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

const StreamAPI = {
    streamImage: streamImage
};

module.exports = StreamAPI;
