import React from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import "moment/locale/it";
import {Utils} from "custom/index";
const moment = require("moment");

const MobileFooterSticky = ({ dates, setDates, daysLabel, formOrder, closeCheckout, setFormOrder, beachResort, selectedItemForm }) => {

    let oneDay = (moment(dates.startDate, "YYYY-MM-DD").diff(moment(dates.endDate, "YYYY-MM-DD")) === 0)




    return (
        <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
            <div className="container flex items-center justify-between">
                <div className="">
                    {(dates.startDate && dates.endDate && formOrder.bill_frontend > 0) ? <span className="block text-lg font-semibold">
                        {Utils.getValueMoneyLabel(formOrder.bill_frontend)}
                        <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                          {daysLabel}
                        </span>
                  </span> : null}
                    {(formOrder.booking.id_item === null) ? <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                          Seleziona una postazione
                        </span> : null}
                    {(dates.startDate !== null && dates.endDate !== null) ? (
                        <ModalSelectDate
                            dates={dates}
                            onDateChange={setDates}
                            renderChildren={({ openModal }) => (
                                <span
                                    onClick={openModal}
                                    className="block text-sm underline font-medium"
                                >
                                    {(oneDay) ? moment(dates.startDate, "YYYY-MM-DD").format("ddd DD MMM YYYY") : converSelectedDateToString([
                                        new Date(moment(dates.startDate, "YYYY-MM-DD").format("YYYY/MM/DD")),
                                        new Date(moment(dates.endDate, "YYYY-MM-DD").format("YYYY/MM/DD"))
                                    ])}
                                </span>
                            )}
                        />
                    ) : null}
                </div>
                {(selectedItemForm) ? <ModalReserveMobile
                    formOrder={formOrder}
                    setFormOrder={setFormOrder}
                    selectedItemForm={selectedItemForm}
                    beachResort={beachResort}
                    closeCheckout={closeCheckout}
                    renderChildren={({ openModal }) => (
                        <ButtonPrimary
                            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                            onClick={openModal}
                        >
                            Conferma
                        </ButtonPrimary>
                    )}
                /> : null}
            </div>
        </div>
    );
};

export default MobileFooterSticky;
