import React, {useEffect, useState} from "react";
import {Utils} from "custom/index";
import {StreamAPI} from "api/index";


const BillDetailWidget = ({beachResort, days, optionalForm, selectedItem}) => {

  const [totalOptionals, setTotalOptionals] = useState(0);
  const [priceDayBooking, setPriceDayBooking] = useState(0);
  const [totalBooking, setTotalBooking] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const [image, setImage] = useState(null);

  useEffect(() => {

    let totOpt = Utils.calcBookingPriceOptional(optionalForm, selectedItem.optionals || [])

    setTotalOptionals(totOpt)
    setPriceDayBooking(selectedItem.price/days)
    setTotalBooking(selectedItem.price)
    setTotalCommission(Math.floor((selectedItem.price + totOpt) * beachResort.commission_client_percentage/100) + 0.99)

  }, [selectedItem, optionalForm])

  useEffect(() => {


    if (beachResort.main_photo) {
      StreamAPI.streamImage(beachResort.main_photo).then((url) => {
        setImage(url)
      })
    }



  }, [])

  return (
      <div className="flex-grow">
        <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="flex-shrink-0 w-full sm:w-40">
                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                  <img
                      alt=""
                      className="absolute inset-0 object-cover"
                      sizes="200px"
                      src={image}
                  />
                </div>
              </div>
              <div className="py-5 sm:px-5 space-y-3">
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    {beachResort?.locality}
                  </span>
                      <span className="text-xl font-medium mt-1 block">
                    {beachResort?.name}
                  </span>
                </div>
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              </div>
            </div>

            <div className="py-5 sm:px-5 space-y-1">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  Prenotazione per:
                </span>
                <span className="text-base font-medium mt-1 block">
                  {selectedItem?.service}
                </span>
              </div>
              <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                {"n. " + selectedItem?.name}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{Utils.getValueMoneyLabel(priceDayBooking)} x {days} giorni</span>
              <span>{Utils.getValueMoneyLabel(totalBooking)}</span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Allestimento</span>
              <span>{Utils.getValueMoneyLabel(totalOptionals)}</span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Costi di servizio</span>
              <span>{Utils.getValueMoneyLabel(totalCommission)}</span>
            </div>

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Totale prenotazione</span>
              <span>{Utils.getValueMoneyLabel(totalCommission + totalBooking + totalOptionals)}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BillDetailWidget;
