
let isValidEmail = function (text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(text);
};

let isValidPassword = function (text) {
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    return reg.test(text);
};

let isValidPhone = function (text) {
    let reg = /^\+(?:[0-9]●?){8,12}[0-9]$/im;
    // let reg = /^[0-9]{5,10}$/;
    return reg.test(text);
};

let isValidCell = function (text) {
    let reg = /^\+[1-9]{1}[0-9]{3,14}$/im;
    return reg.test(text);
};

let isValidFiscalCode = function (text) {
    let reg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
    return reg.test(text);
};

let isValidIban = function (text) {
    let reg = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/gm;
    return reg.test(text);
};


let isPiva = function (text) {
    let reg = /^[0-9]{11}$/;
    return reg.test(text);
};

let isValidSwiftCode = function (text) {
    let reg = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return reg.test(text);
};

let isPercentage = function (number) {
    if (isNaN(number))
        return false;

    return (number >= 0 && number <= 100);
};


let getValueMoneyLabel = function (number) {
    if (number === null) {
        return '0,00 €';
    }

    if (typeof number !== 'number') {
        return '0,00 €';
    }
    return String(number.toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +  ' €';
};

let isValidUrl = function (text) {
    let reg = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    return reg.test(text);
};

let isUserLoggedIn = function () {
    return (localStorage.getItem('hash') !== null);
}

let getUserData = function () {
    return (JSON.parse(sessionStorage.getItem('userData')));
}

let checkConnection = function () {
    return new Promise(function (resolve, reject) {
        let condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            fetch(global.route.test, {
                method: 'GET'
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 'TEST_OK')
                        resolve();
                    else
                        reject();
                })
                .catch((e) => {
                    reject();
                });

        } else {
            reject();
        }
    });
}


let getRoleLabel = function (role) {
    switch(role) {
        case "ADMIN":
            return "AMMINISTRATORE";
        case "PARTNER":
            return "PARTNER";
        case "BEACH_RESORT":
            return "STABILIMENTO";
        default:
            return "--";
    }
}



let getPaymentLabel = function (role) {
    switch(role) {
        case "CASH":
            return "Contanti";
        case "BANK_TRANSFER":
            return "Bonifico bancario";
        case "CARD":
            return "Carte";
        case "CHECK":
            return "Assegno";
        case "OTHER":
            return "Altro";
        default:
            return "--";
    }
}

let getPaymentMethod = function () {
    return [
        {value: 'CASH', label: 'Contanti'},
        {value: 'CARD', label: 'Carte'},
        {value: 'BANK_TRANSFER', label: 'Bonifico Bancario'},
        {value: 'CHECK', label: 'Assegno'},
        {value: 'OTHER', label: 'Altro'}
    ]
}

let calcBookingPriceOptional = function (confirmSelectOptionals, optionalsItem) {
    let totalPrice = 0;

    confirmSelectOptionals.forEach(selected => {
        let optional = optionalsItem.find(item => item.id === selected.id);
        if (optional) {
            let excessQuantity = Math.max(0, selected.quantity - optional.default);
            totalPrice += excessQuantity * optional.price;
        }
    });

    return totalPrice;
}


const Utils = {

    isValidEmail: isValidEmail,
    isValidPassword: isValidPassword,
    isValidPhone: isValidPhone,
    isValidCell: isValidCell,
    isValidFiscalCode: isValidFiscalCode,
    isValidIban: isValidIban,
    isValidSwiftCode: isValidSwiftCode,
    isPiva: isPiva,
    isValidUrl: isValidUrl,
    isPercentage: isPercentage,
    getValueMoneyLabel: getValueMoneyLabel,
    isUserLoggedIn: isUserLoggedIn,
    checkConnection: checkConnection,
    getRoleLabel: getRoleLabel,
    getUserData: getUserData,
    getPaymentLabel: getPaymentLabel,
    getPaymentMethod: getPaymentMethod,
    calcBookingPriceOptional: calcBookingPriceOptional
};

module.exports = Utils;
