let searchResult = function (typology, locality, start_date, end_date) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.searchPlace + "?typology=" + typology + "&locality=" + locality + ((start_date !== null) ? ("&start_date=" + start_date) : "") + ((end_date !== null) ? "&end_date=" + end_date : ""), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('place search ok', responseJson);
                if (responseJson.status === "PLACE_SEARCH_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

const SearchAPI = {
    searchResult: searchResult
};

module.exports = SearchAPI;
