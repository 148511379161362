
let server = process.env.REACT_APP_API_URL;

let frontendRoute = process.env.REACT_APP_FRONTEND_URL;

let productionMode = (process.env.REACT_APP_NODE_ENV === "production"); // valorizzare in TRUE per produzione
let versionApp = process.env.REACT_APP_VERSION; //Valorizzare per cambiare versione applicazione
let emailAssistance = process.env.REACT_APP_API_EMAIL_ASSISTANCE; //email assistenza
let route = {
  test: '/test',

  // geo service
  autocompleteGeo: '/online/booking/autocomplete',

  // search service
  searchPlace: '/online/booking/search',
  detailStructure: '/online/booking/detail',

  // booking service
  signVisit: "/online/booking/signvisit",
  getMap: '/online/booking/map',
  getItem: '/online/booking/item',
  sendCheckout: '/online/booking/checkout',
  getBooking: '/online/booking/order',

  // stream image
  streamImage: '/image/',

  // auth
  permission: '/auth/permission',
  login: '/auth/signin',
  credentialEdit: '/auth/changepsw',
  resetPassword: '/auth/forgotpsw',
  completeResetPassword: '/auth/forgotpsw/reset',
  logout: "/auth/logout",



};



for (var i in route) {
  route[i] = server + route[i];
}
global.server = server;
global.frontend = frontendRoute;
global.productionMode = productionMode;
global.versionApp = versionApp;
global.emailAssistance = emailAssistance;
global.route = route;
module.export = route;
