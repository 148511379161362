import {PencilSquareIcon, CheckCircleIcon, StopIcon, QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import React, {FC, useEffect, useRef, useState} from "react";
import NcModal from "shared/NcModal/NcModal";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {Loading, Utils} from "custom/index";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

import BillDetailWidget from "../MapPage/BillDetailWidget";
import {BookingAPI} from "api/index";
import {useLocation, useParams} from "react-router-dom";
import BgGlassmorphism from "../../components/BgGlassmorphism/BgGlassmorphism";

import TermsPage from "./TermsPage";

import PrivacyPage from "./PrivacyPage";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Alert, AlertTitle, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';


const moment = require("moment")


export interface CheckOutPagePageMainProps {
    className?: string;
    formOrder?: any;
    setFormOrder?: any;
    selectedItemForm?: any;
    beachResort?: any;
    setRevert?: any;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
                                                                 className = "",
                                                                 formOrder,
                                                                 setFormOrder,
                                                                 selectedItemForm,
                                                                 beachResort,
                                                                 setRevert
                                                             }) => {

    const {genToken} = useParams();

    const [loading, setLoading] = useState(false);

    const [openTermsModal, setOpenTermsModal] = useState(false);

    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

    const [openConditionModal, setOpenConditionModal] = useState(false);

    const [errorPayment, setErrorPayment] = useState(null);

    const [priceChanged, setPriceChanged] = useState(false);
    const [newPrice, setNewPrice] = useState(null);

    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        email: false,
        cell: false
    });

    const formRef = useRef<HTMLFormElement>(null);

    const handleOpenTermsModal = () => setOpenTermsModal(true);
    const handleCloseTermsModal = () => setOpenTermsModal(false);

    const handleOpenPrivacyModal = () => setOpenPrivacyModal(true);
    const handleClosePrivacyModal = () => setOpenPrivacyModal(false);

    const handleOpenConditionModal = () => setOpenConditionModal(true);
    const handleCloseConditionModal = () => setOpenConditionModal(false);

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const status = query.get('status');

    useEffect(() => {
        if (beachResort.payment_online_allowed.length === 1) {
            setChangePaymentMode(beachResort.payment_online_allowed[0])
        }

        if (status === "false")
            setErrorPayment("Si è verificato un errore durante il pagamento")

    }, [])

    const setChangePaymentMode = (payment) => {

        const updatedFormOrder = {
            ...formOrder,
            booking: {
                ...formOrder.booking,
                payment_intent: payment
            }
        };

        setFormOrder(updatedFormOrder);
    }

    const handleCellChange = (e) => {
        let value = e.target.value;

        if (value[0] === "+") {
            value = "+" + value.slice(1).replace(/\D/g, "");
        } else {
            value = value.replace(/\D/g, "");
        }

        if (value.length >= 3 && value[0] !== "+") {
            value = "+39" + value;
        }

        changeFormClient("cell", value);
    };

    const changeFormClient = (key, value) => {

        let updatedFormOrder

        if (key === "order_note")  {
            updatedFormOrder = {
                ...formOrder,
                booking: {
                    ...formOrder.booking,
                    [key]: value
                }
            };
        } else {
            updatedFormOrder = {
                ...formOrder,
                client: {
                    ...formOrder.client,
                    [key]: value
                }
            };
        }

        setFormOrder(updatedFormOrder);
    };

    const renderSidebar = () => {
        let days = moment(formOrder.booking.to_date, "YYYY-MM-DD").diff(moment(formOrder.booking.from_date, "YYYY-MM-DD"), "days") + 1;
        return (
            <BillDetailWidget
                beachResort={beachResort}
                days={days}
                optionalForm={formOrder.optionals}
                selectedItem={selectedItemForm}
            />
        );
    };

    const validateForm = () => {
        const newErrors = {};

        // @ts-ignore
        if (!formOrder.client?.firstname) newErrors.firstname = "Il nome è obbligatorio";
        // @ts-ignore
        if (!formOrder.client?.lastname) newErrors.lastname = "Il cognome è obbligatorio";
        // @ts-ignore
        if (!formOrder.client?.email) newErrors.email = "L'email è obbligatoria";
        // @ts-ignore
        if (!formOrder.client?.cell) newErrors.cell = "Il cellulare è obbligatorio";
        // @ts-ignore
        if (formOrder.client?.cell && !/^\+?\d{10,}$/.test(formOrder.client.cell)) {
            // @ts-ignore
            newErrors.cell = "Numero di cellulare non valido";
        }

        // @ts-ignore
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const confirmCheckout = (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        console.log(e)
        console.log(formOrder);

        const formData = new FormData(e.target);

        let formToSend = formOrder;

        formToSend.client = {
            firstname: formData.get("firstname"),
            lastname: formData.get("lastname"),
            email: formData.get("email"),
            cell: formData.get("cell")
        }

        formToSend.booking.order_note = formData.get("order_note")

        setLoading(true)

        BookingAPI.sendCheckout(genToken, formToSend).then((url) => {
            window.location.replace(url);
        }).catch((err) => {
            setLoading(false)
            console.error(err)
            if (err?.status === "PRICE_CHANGED") {
                setNewPrice(err.newBill);
                setPriceChanged(true);
            } else if (err?.status === "BOOKING_NOT_FOUND") {
                window.location.reload()
            } else {
                setErrorPayment("Si è verificato un errore durante la conferma della prenotazione")
            }
        })

    }

    const handlePriceChangeConfirm = () => {
        setFormOrder((prevFormOrder) => ({
            ...prevFormOrder,
            bill_frontend: newPrice,
        }));
        setPriceChanged(false);
        confirmCheckout(new Event('submit'));
    };

    const renderMain = () => {

        let payment_intent = formOrder?.booking.payment_intent

        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <form ref={formRef} onSubmit={confirmCheckout}>
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    Conferma Prenotazione
                </h2><br />
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <NcModal
                        renderTrigger={(openModal) => (
                            <span
                                onClick={() => openModal()}
                                className="block lg:hidden underline mt-1 cursor-pointer"
                            >
                            Dettagli prenotazione
                          </span>
                        )}
                        renderContent={renderSidebar}
                        modalTitle="Booking details"
                    />
                    <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                        <div onClick={() => setRevert("DATE")}
                             className="cursor-pointer text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Date</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                  {converSelectedDateToString([new Date(moment(formOrder.booking.from_date).format("YYYY/MM/DD")), new Date(moment(formOrder.booking.to_date).format("YYYY/MM/DD"))])}
                                </span>
                            </div>
                            <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                        </div>
                        <div onClick={() => setRevert("ITEM")}
                             className="cursor-pointer text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800">
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Postazione n. {selectedItemForm?.name}</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                  {selectedItemForm.service}
                                </span>
                            </div>
                            <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div>
                    {(beachResort.payment_online_allowed.length > 1) ? <React.Fragment><h3 className="text-2xl font-semibold">Scegli come pagare</h3>
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div></React.Fragment> : null}
                    <div className="mt-6">
                        {(beachResort.payment_online_allowed.includes("CARD")) ? <div onClick={() => setChangePaymentMode("CARD")} className={"cursor-pointer text-left flex-1 p-5 flex justify-between space-x-5 " + ((payment_intent === "CARD") ? "bg-neutral-100 dark:bg-neutral-800" : "hover:bg-neutral-50 dark:hover:bg-neutral-800")}>
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400"></span>
                                <span className="mt-1.5 text-lg font-semibold">
                                  Paga adesso {Utils.getValueMoneyLabel(formOrder?.bill_frontend)}
                                </span>
                            </div>
                            {(payment_intent === "CARD") ? <CheckCircleIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/> : <StopIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/>}
                        </div> : null}

                        {(formOrder.bill_frontend > 100 && beachResort.payment_online_allowed.includes("KLARNA")) ? <div onClick={() => setChangePaymentMode("KLARNA")}
                            className={"cursor-pointer text-left flex-1 p-5 flex justify-between space-x-5 " + ((payment_intent === "KLARNA") ? "bg-neutral-100 dark:bg-neutral-800" : "hover:bg-neutral-50 dark:hover:bg-neutral-800")}>
                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Senza interessi con Klarna</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                  Paga in 3 rate da {Utils.getValueMoneyLabel(formOrder?.bill_frontend / 3)}
                                </span>
                            </div>
                            {(payment_intent === "KLARNA") ? <CheckCircleIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/> : <StopIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/>}
                        </div> : null}

                        {(beachResort.payment_online_allowed.includes("CASH")) ? <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div> : null}

                        {(beachResort.payment_online_allowed.includes("CASH")) ? <div onClick={() => setChangePaymentMode("CASH")}
                            className={"cursor-pointer text-left flex-1 p-5 flex justify-between space-x-5 " + ((payment_intent === "CASH") ? "bg-neutral-100 dark:bg-neutral-800" : "hover:bg-neutral-50 dark:hover:bg-neutral-800")}>
                            <div className="flex flex-col">
                                <span
                                    className="text-sm text-neutral-400">Pagherai {Utils.getValueMoneyLabel(formOrder?.bill_frontend)}</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                    Paga in Struttura
                                  </span>
                            </div>
                            {(payment_intent === "CASH") ? <CheckCircleIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/> : <StopIcon className="w-8 h-8 text-neutral-6000 dark:text-neutral-400 mt-3"/>}
                        </div> : null}

                        {(payment_intent) ? <div className="mt-6">
                            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                            <div className="space-y-1 mt-2">
                                <Label>Nome* </Label>
                                <Input
                                    name="firstname"
                                    required
                                    type="text"
                                    placeholder="Inserisci il tuo nome"
                                    onChange={(e) => changeFormClient("firstname", e.target.value)}
                                    value={formOrder.client?.firstname}
                                    className={errors.firstname ? "border-red-500" : ""}
                                />
                                {errors.firstname && <p className="text-red-500">{errors.firstname}</p>}
                            </div>
                            <div className="space-y-1 mt-5">
                                <Label>Cognome* </Label>
                                <Input
                                    name="lastname"
                                    required
                                    type="text"
                                    placeholder="Inserisci il tuo cognome"
                                    onChange={(e) => changeFormClient("lastname", e.target.value)}
                                    value={formOrder.client?.lastname}
                                    className={errors.lastname ? "border-red-500" : ""}
                                />
                                {errors.lastname && <p className="text-red-500">{errors.lastname}</p>}
                            </div>
                            <div className="space-y-1 mt-5">
                                <Label>Cellulare* </Label>
                                <Input
                                    name="cell"
                                    required
                                    type="tel"
                                    autoComplete="tel"
                                    placeholder="Inserisci il tuo cellulare"
                                    onChange={handleCellChange}
                                    value={formOrder.client?.cell}
                                    className={errors.cell ? "border-red-500" : ""}
                                />
                                {errors.cell && <p className="text-red-500">{errors.cell}</p>}
                            </div>
                            <div className="space-y-1 mt-5">
                                <Label>Email* </Label>
                                <Input
                                    name="email"
                                    required
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Inserisci la tua Email"
                                    onChange={(e) => changeFormClient("email", e.target.value)}
                                    value={formOrder.client?.email}
                                    className={errors.email ? "border-red-500" : ""}
                                />
                                {errors.email && <p className="text-red-500">{errors.email}</p>}
                            </div>
                            <div className="space-y-1 mt-5">
                                <Label>Nota Prenotazione</Label>
                                <Textarea name="order_note" placeholder="" value={formOrder.booking?.order_note} onChange={(e) => changeFormClient("order_note", e.target.value)} />
                                <span className="text-sm text-neutral-500 block">

                                </span>
                            </div>
                        </div> : null}

                        <br/>

                        {(payment_intent) ? <div className="bg-yellow-100 p-4 rounded-lg mb-4">
                            <span className="text-yellow-700 font-semibold">Condizioni della prenotazione</span>
                            <p className="text-sm text-yellow-700"><Box sx={{display: "flex", flexDirection: "row"}}><QuestionMarkCircleIcon onClick={handleOpenConditionModal} className="cursor-pointer w-5 text-yellow-6000 dark:text-yellow-400"/>&nbsp;&nbsp;Prenotazione non rimborsabile</Box></p>
                        </div> : null}

                        <br/>
                        <br/>

                        {(payment_intent) ? <span className="text-sm font-light">
                          Proseguendo confermi di aver letto, compreso e di accettare per intero le pagine <span className="cursor-pointer" onClick={handleOpenTermsModal}><u>Termini e Condizioni</u></span> e <span className="cursor-pointer" onClick={handleOpenPrivacyModal}><u>Privacy Policy</u></span> della nostra piattaforma
                        </span> : null}

                        {(payment_intent === "CASH") ? <span className="text-sm font-medium">
                            <br/>
                            <br/>
                            Per garantire la tua prenotazione, proseguendo ti sarà richiesto di inserire un metodo di pagamento. Non preoccuparti, <u>non verrà addebitato alcun importo</u>. La tua carta serve solo come garanzia e il pagamento effettivo avverrà direttamente in struttura, come da tua scelta.
                        </span> : null}

                        {(errorPayment) ? <div className="bg-red-200 p-4 rounded-lg mb-4 mt-6">
                            <span className="text-red-700 font-semibold">{errorPayment}</span>
                            <p className="text-sm text-red-700"></p>
                        </div> : null}

                        {(payment_intent) ? <div className="pt-8">
                            <ButtonPrimary type="submit" >{(payment_intent === "CASH") ? "Conferma Prenotazione" : "Procedi al pagamento"}</ButtonPrimary>
                        </div> : null}

                        {(payment_intent) ? <div>
                            <img className="mt-6" src={require("../../assets/payment_strype.png")} width="300px"/>

                            <img className="mt-3" src={require("../../assets/powered_by_stripe.png")} width="100px"/>
                        </div> : null}

                    </div>
                </div>
            </form>
            </div>
        );
    };

    return (
        <div className={`nc-CheckOutPagePageMain ${className}`}>
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10000,
                }}>
                    <BgGlassmorphism/>
                    <Loading loadingText="Conferma della prenotazione in corso..."/>
                </div>
            )}
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row" style={{opacity: (loading) ? 0.3 : 1}}>
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderMain()}</div>
                <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
            </main>
            <Modal open={openTermsModal} onClose={handleCloseTermsModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        width: '90%',
                        maxWidth: 800,
                    }}
                >
                    <TermsPage />
                </Box>
            </Modal>

            <Modal open={openPrivacyModal} onClose={handleClosePrivacyModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        width: '90%',
                        maxWidth: 800,
                    }}
                >
                    <PrivacyPage />
                </Box>
            </Modal>

            <Modal open={openConditionModal} onClose={handleCloseConditionModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '80vh',
                        width: '90%',
                        maxWidth: 800,
                    }}
                >
                    <div className="terms-container">
                        <h1>Condizioni della prenotazione</h1>

                        {(formOrder?.booking.payment_intent !== "CASH") ? <><h3 className="font-semibold">Pagamento anticipato</h3>
                        <p>
                            L'importo totale della prenotazione viene addebitato al momento della prenotazione
                        </p></> : <><h3 className="font-semibold">Pagamento in Struttura</h3>
                        <p>
                            L'importo totale della prenotazione dovrà essere pagato in struttura
                        </p></>}

                        <h3 className="font-semibold">Non rimborsabile</h3>
                        <p>
                            In caso di cancellazione, modifica o mancata presentazione, la penale sarà pari costo della prenotazione
                        </p>

                        <h3 className="font-semibold">Calendario per il rimborso</h3>
                        <p>
                            Se cancelli questa prenotazione, non potrai ottenere nessun rimborso.
                        </p>
                    </div>
                </Box>
            </Modal>

            <Dialog
                open={priceChanged}
                onClose={() => setPriceChanged(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Prezzo Cambiato"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Attenzione! Purtroppo il prezzo è cambiato. Il nuovo prezzo è {Utils.getValueMoneyLabel(newPrice)}.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePriceChangeConfirm} color="primary">
                        CONFERMA
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CheckOutPagePageMain;
