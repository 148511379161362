import React, { useState, FC } from "react";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import StayDatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";

interface StaySearchFormProps {
    location: { name: string; province?: string; slug?: string };
    dates: { startDate: Date | null; endDate: Date | null };
    setLocation: (location: { name: string; province?: string; slug?: string }) => void;
    setDates: (dates: { startDate: Date | null; endDate: Date | null }) => void;
}

const StaySearchForm: FC<StaySearchFormProps> = ({
                                                     location,
                                                     dates,
                                                     setLocation,
                                                     setDates,
                                                 }) => {
    const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates">((location.name !== "") ? "dates" : "location");


    const handleChangeDates = (e) => {
        setDates(e)
        if (e.startDate !== null && e.endDate !== null) {
            setFieldNameShow(null)
        }
    }

    const renderInputLocation = () => {
        const isActive = fieldNameShow === "location";
        return (
            <div
                className={`w-full bg-white dark:bg-neutral-800 ${
                    isActive
                        ? "rounded-2xl shadow-lg"
                        : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
                }`}
            >
                {!isActive ? (
                    <button
                        className={`w-full flex justify-between text-sm font-medium p-4`}
                        onClick={() => setFieldNameShow("location")}
                    >
                        <span className="text-neutral-400">Dove?</span>
                        <span>{location.name || "Seleziona Località"}</span>
                    </button>
                ) : (
                    <LocationInput
                        defaultValue={location.name}
                        onChange={(value) => {
                            setLocation({ ...location, name: value });
                            setFieldNameShow("dates");
                        }}
                    />
                )}
            </div>
        );
    };

    const renderInputDates = () => {
        const isActive = fieldNameShow === "dates";
        return (
            <div
                className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
                    isActive
                        ? "rounded-2xl shadow-lg"
                        : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
                }`}
            >
                {!isActive ? (
                    <button
                        className={`w-full flex justify-between text-sm font-medium p-4  `}
                        onClick={() => setFieldNameShow("dates")}
                    >
                        <span className="text-neutral-400">Quando?</span>
                        <span>
              {dates.startDate
                  ? converSelectedDateToString([dates.startDate, dates.endDate])
                  : "Seleziona i giorni"}
            </span>
                    </button>
                ) : (
                    <StayDatesRangeInput
                        value={dates}
                        onChange={handleChangeDates}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="w-full space-y-5">
                {renderInputLocation()}
                {renderInputDates()}
            </div>
        </div>
    );
};

export default StaySearchForm;
