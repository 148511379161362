import React, {FC, useEffect, useState} from "react";
import {useLocation, useParams, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridFilterCard from "./SectionGridFilterCard";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import {SearchAPI} from "api";

const moment = require("moment");

export interface ListingStayPageProps {
    className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({className = ""}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {tipology, locality} = useParams();

    const [searchParams, setSearchParams] = useState(null);
    const [titleSEO, setTitleSEO] = useState("");

    const [results, setResults] = useState<any[]>([]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const startDate = params.get("start_date");
        const endDate = params.get("end_date");

        if (startDate !== null || endDate !== null)
            if (!moment(startDate, "YYYY-MM-DD").isValid() || !moment(endDate, "YYYY-MM-DD").isValid()) {
                navigate("/", {replace: true});
                return;
            }

        setSearchParams(null);

        SearchAPI.searchResult(tipology, locality, startDate, endDate).then((predictions) => {

            setSearchParams({
                slugTipology: tipology || "",
                slugLocality: locality || "",
                startDate: startDate ? new Date(startDate) : null,
                endDate: endDate ? new Date(endDate) : null,
                localityCity: predictions.localityCity,
                localityForm: predictions.localityForm
            });

            if (tipology === "stabilimenti-balneari")
                setTitleSEO("Stabilimenti Balneari a " + predictions.localityCity)

            setResults(predictions.list || []);

        }).catch((e) => {
            navigate("/", {replace: true});
        });

    }, [location.search, tipology, locality, navigate]);

    return (
        <div className={`nc-ListingStayPage relative overflow-hidden ${className}`} data-nc-id="ListingStayPage">
            <Helmet>
                <title>{titleSEO} - Easy Beach</title>
                <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
            </Helmet>
            <BgGlassmorphism/>

            <div className="container relative overflow-hidden">
                <SectionHeroArchivePage
                    currentPage="Beach"
                    currentTab="Beach"
                    className="pt-10 pb-14 lg:pb-18 lg:pt-16 "
                    searchParams={searchParams}
                />

                <SectionGridFilterCard className="pb-14 lg:pb-18" data={results} searchParams={searchParams}/>

                <div className="relative py-16">
                    <BackgroundSection/>
                    <SectionBecomeAnAuthor/>
                </div>
            </div>
        </div>
    );
};

export default ListingStayPage;
