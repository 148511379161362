import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { useLocation } from "react-router-dom";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    // {
    //   id: "1",
    //   title: "Informazioni",
    //   menus: [
    //     { href: "/", label: "Chi Siamo" },
    //     { href: "/", label: "Come Funziona" },
    //     { href: "/", label: "Impegno per l'Ambiente" },
    //     { href: "/", label: "Blog" }
    //   ],
    // },
    // {
    //   id: "2",
    //   title: "Servizi",
    //   menus: [
    //     { href: "/", label: "Tariffe e Servizi" },
    //     { href: "/", label: "Testimonianze" },
    //     { href: "/", label: "Partner" },
    //     { href: "/", label: "Lavora con Noi" }
    //   ],
    // },
    // {
    //   id: "3",
    //   title: "Supporto",
    //   menus: [
    //     { href: "/", label: "Contatti" },
    //     { href: "/", label: "Supporto Clienti" },
    //     { href: "/", label: "Area Riservata" },
    //     { href: "/", label: "FAQ" },
    //   ],
    // },
    {
      id: "4",
      title: "",
      menus: [
        { href: "/terms", label: "Termini e Condizioni" },
        { href: "/privacy-policy", label: "Privacy Policy" },
        { href: "/cookie-policy", label: "Cookie Policy" }
      ],
    },
];

const Footer: React.FC = () => {
    const location = useLocation();

    if (location.pathname.startsWith("/booking")) {
        return null;
    }

    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
                    {menu.title}
                </h2>
                <ul className="mt-5 space-y-4">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            <a
                                key={index}
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                href={item.href}
                            >
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2 md:col-span-1">
                        <Logo />
                    </div>
                    <div className="col-span-2 flex items-center md:col-span-3">
                        <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>
            <div className="container mt-10 border-t border-neutral-200 dark:border-neutral-700 pt-6">
                <p className="text-center text-sm text-neutral-600 dark:text-neutral-300">
                    Easy Beach © {new Date().getFullYear()}. Tutti i diritti riservati.<br />Piattaforma realizzata da <a href="https://www.fbwebstudio.it" >FB Web Studio</a>.<br />P.IVA: IT02322060688
                </p>
            </div>
        </div>
    );
};

export default Footer;
