import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import ModalReserveMobile from "../../containers/ListingDetailPage/(components)/ModalReserveMobile";
import ModalStructureMobile from "../../containers/ListingDetailPage/(components)/ModalStructureMobile";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Hai in gestione una Spiaggia?
        </h2>
        <h5 className="text-xl md:text-2xl xl:text-3xl !leading-[114%] text-neutral-600 dark:text-neutral-300 mt-4">
          Unisciti a Easy Beach e Fai Crescere il Tuo Business
        </h5>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Registrando la tua struttura sul nostro portale, potrai raggiungere un numero maggiore di clienti potenziali che utilizzano la nostra piattaforma per trovare e prenotare spiagge.
        </span>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">

        </span>
        {/*<ButtonPrimary className="mt-6 sm:mt-11">*/}
        {/*  Inserisci la tua struttura*/}
        {/*</ButtonPrimary>*/}
        {/*<ModalStructureMobile*/}
        {/*    renderChildren={({ openModal }) => (*/}
        {/*        <ButtonPrimary*/}
        {/*            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"*/}
        {/*            onClick={openModal}*/}
        {/*        >*/}
        {/*          Inserisci la tua struttura*/}
        {/*        </ButtonPrimary>*/}
        {/*    )}*/}
        {/*/>*/}
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
