import { ClockIcon, MapPinIcon, MapIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import { CircularProgress } from "@mui/material";
import { GeoAPI } from "api";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  setLocation: (location: string) => void;
  initialLocation?: string;
}

export interface SearchResult {
  name?: string;
  province?: string;
  slug?: string;
}

const LocationInput: FC<LocationInputProps> = ({
         setLocation,
         autoFocus = false,
         placeHolder = "Località",
         desc = "Dove vuoi andare?",
         className = "nc-flex-1.5",
         divHideVerticalLineClass = "left-10 -right-0.5",
         initialLocation = "",
       }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(initialLocation);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    const storedSearches = localStorage.getItem("recentSearches");
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;

    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }

    setShowPopover(false);
  };

  const handleSelectLocation = (item: SearchResult) => {
    setValue(item.name || "");
    setShowPopover(false);
    setLocation(item.slug || "");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      setLoading(true);
      fetchSearchResults(newValue);
    }, 1000));
  };

  const fetchSearchResults = (query: string) => {
    if (!query) {
      setLoading(false);
      return;
    }

    GeoAPI.autocomplete(query).then((predictions) => {
      setSearchResults(predictions);
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
    });
  };

  const renderRecentSearches = () => {
    return (
        <>
          <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
            Ricerche recenti
          </h3>
          <div className="mt-2">
            {recentSearches.map((item: any) => (
                <span
                    onClick={() => handleSelectLocation(item )}
                    key={item}
                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                >
                      <span className="block text-neutral-400">
                          <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                      </span>
                      <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                          {item.name}
                      </span>
                  </span>
            ))}
          </div>
        </>
    );
  };

  const renderSearchResults = () => {
    if (loading) {
      return (
          <div className="flex justify-center items-center py-4">
            <CircularProgress />
          </div>
      );
    }

    return (
        <>
          {searchResults.map((item, index) => (
              <span
                  onClick={() => handleSelectLocation(item)}
                  key={index}
                  className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                        <span className="block text-neutral-400">
                            <MapIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                        </span>
                        <div className="block">
                            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                                {item.name}
                            </span>
                            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                                {item.province}
                            </span>
                        </div>
                    </span>
          ))}
        </>
    );
  };

  return (
      <div className={`relative flex ${className}`} ref={containerRef}>
        <div
            onClick={() => setShowPopover(true)}
            className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
                showPopover ? "nc-hero-field-focused" : ""
            }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
          </div>
          <div className="flex-grow">
            <input
                className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                placeholder={placeHolder}
                value={value}
                autoFocus={showPopover}
                onChange={handleInputChange}
                ref={inputRef}
            />
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                        <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
                    </span>
            {value && showPopover && (
                <ClearDataButton
                    onClick={() => {
                      setValue("");
                      setSearchResults([]);
                      setLocation("");
                    }}
                />
            )}
          </div>
        </div>

        {showPopover && (
            <div
                className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
            ></div>
        )}

        {showPopover && (
            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
              {value ? renderSearchResults() : renderRecentSearches()}
            </div>
        )}
      </div>
  );
};

export default LocationInput;
