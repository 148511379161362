import StartRating from "components/StartRating/StartRating";
import React, {FC, useEffect, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import {Loading, Utils} from "custom/index";
import {Helmet} from "react-helmet";
import Logo from "../../shared/Logo/Logo";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import ButtonThird from "../../shared/Button/ButtonThird";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BookingAPI, StreamAPI} from "api/index";
import moment from "moment";


const BookingGestPage: FC = () => {

    const [imgStructure, setImgStructure] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [structure, setStructure] = useState(null);
    const [booking, setBooking] = useState(null);

    const navigate = useNavigate();
    const {bookingToken} = useParams();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const status = query.get('status');

    useEffect(() => {
        getBooking()
    }, [])

    const getBooking = () => {
        BookingAPI.getBooking(bookingToken).then(async (res) => {
            console.log(res)
            if (res.structure.img) {
                let image = await StreamAPI.streamImage(res.structure.img);
                if (image)
                    setImgStructure(image)
            }

            sessionStorage.removeItem("genToken")

            setStructure(res.structure)
            setBooking(res.booking)

            setLoading(false)
            setError(false)
        }).catch((err) => {
            setError(true)
            console.error(err)
        })
    }

    const renderContent = () => {

        let days = 0;

        if (booking !== null)
            days = moment(booking.from_date, "YYYY-MM-DD").diff(moment(booking.to_date, "YYYY-MM-DD"), "days") + 1;

        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">

                <Helmet>
                    <title>Gestione Prenotazione - Easy Beach</title>
                </Helmet>

                <div className="block fixed top-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40 opacity-95">
                    <div className="container flex items-center justify-center">
                        <Logo/>
                    </div>
                </div>

                {(error) ? <React.Fragment>
                    <h2 className="text-3xl lg:text-4xl font-semibold">
                        Prenotazione Non Trovata 🔍
                    </h2>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                    {/* ------------------------ */}
                    <div className="space-y-6">
                        <h3 className="text-2xl font-semibold">La prenotazione richiesta non è disponibile</h3>
                    </div>


                    <div className="flex items-center justify-center">
                        <ButtonSecondary href={"tel:+393801978566"}><i className={"las la-phone text-3xl text-neutral-400"}></i>&nbsp;&nbsp;Chiama Assistenza</ButtonSecondary>
                        &nbsp;&nbsp;&nbsp;
                        <ButtonSecondary targetBlank href={"https://api.whatsapp.com/send?phone=393801978566"}><i className={"las la-comments text-3xl text-neutral-400"}></i>&nbsp;&nbsp;Chat Assistenza</ButtonSecondary>
                    </div>
                    <div className="flex items-center justify-center">
                        <ButtonPrimary href="/">Torna alla Home</ButtonPrimary>
                    </div>
                </React.Fragment> : ((loading) ? <Loading loadingText="Caricamento dettaglio prenotazione..." /> : <React.Fragment>
                    {(status === "true") ? <h2 className="text-3xl lg:text-4xl font-semibold">
                        Prenotazione Confermata 🎉
                    </h2> : null}

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                    {/* ------------------------ */}
                    <div className="space-y-6">
                        <h3 className="text-2xl font-semibold">La tua prenotazione</h3>
                        <div className="flex flex-col sm:flex-row sm:items-center">
                            {(imgStructure) ? <div className="flex-shrink-0 w-full sm:w-40">
                                <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                                    <NcImage src={imgStructure}/>
                                </div>
                            </div> : null}
                            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                                <div>
                                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                      {structure.locality}
                                    </span>
                                    <span className="text-base sm:text-lg font-medium mt-1 block">
                                      {structure.name}
                                    </span>
                                </div>
                                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                            </div>
                        </div>
                        <div
                            className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                            <div className="flex-1 p-5 flex space-x-4">
                                <svg
                                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                                        stroke="#D1D5DB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <div className="flex flex-col">
                                    <span className="text-sm text-neutral-400">Periodo</span>
                                    <span className="mt-1.5 text-lg font-semibold">
                                        {(days === 1) ? moment(booking.from_date).format("dddd DD MMMM YYYY") : <span>Da {moment(booking.from_date).format("ddd DD MMM YYYY")}<br />al {moment(booking.to_date).format("ddd DD MMM YYYY")}</span>}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-1 p-5 flex space-x-4">
                                <i className={"las la-umbrella-beach text-3xl text-neutral-400"}></i>

                                <div className="flex flex-col">
                                    <span className="text-sm text-neutral-400">Postazione</span>
                                    <span className="mt-1.5 text-lg font-semibold">{booking.name_item}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ------------------------ */}
                    <div className="space-y-6">
                        <h3 className="text-2xl font-semibold">Dettaglio Prenotazione</h3>
                        <div className="flex flex-col space-y-4">
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Codice Prenotazione</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {booking.code_verification}
                                </span>
                            </div>
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Totale</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {Utils.getValueMoneyLabel(booking.bill)}
                              </span>
                            </div>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Metodo di pagamento</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {(booking.payment_intent === "CASH") ? "In struttura" : "Carta di credito/debito"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <ButtonSecondary href={"tel:" + structure.phone}><i className={"las la-phone text-3xl text-neutral-400"}></i>&nbsp;&nbsp;Chiama</ButtonSecondary>
                        &nbsp;&nbsp;&nbsp;
                        <ButtonSecondary targetBlank href={"https://www.google.com/maps/dir/?api=1&destination=" + structure.lat + "," + structure.lng}><i className={"las la-map text-3xl text-neutral-400"}></i>&nbsp;&nbsp;Indicazioni</ButtonSecondary>
                    </div>
                    <div className="flex items-center justify-center">
                        <ButtonPrimary href="/">Esplora altre Spiagge</ButtonPrimary>
                    </div>
                </React.Fragment>)}
            </div>
        );
    };

    return (
        <div className='nc-PayPage' data-nc-id="PayPage">
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
            </main>
        </div>
    );
};

export default BookingGestPage;
