let signVisit = function (jsonObject) {
    return new Promise(function (resolve, reject) {

        if (!jsonObject)
            return reject("NO_TRACKING");

        fetch((global.route.signVisit + "?fbp=" + jsonObject.fbp + "&fbclid=" + jsonObject.fbclid), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('visit signed ok', responseJson);
                if (responseJson.status === "VISIT_SIGNED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

let getMap = function (genToken, startDate, endDate, metaTrack) {
    return new Promise(function (resolve, reject) {
        let metaParams = "";

        if (metaTrack) {
            metaParams = "&fbclid=" + metaTrack.fbclid + "&fbp=" + metaTrack.fbp
        }

        fetch(global.route.getMap + "?genToken=" + genToken + ((startDate && endDate) ? ("&start_date=" + startDate + "&end_date=" + endDate) : "") + metaParams, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('map detail ok', responseJson);
                if (responseJson.status === "MAP_DETAIL_OK")
                    resolve({map: responseJson.map, beachResortMap: responseJson.beachResort, orderForm: responseJson.orderForm});
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};
let getItem = function (genToken, id_item) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.getItem + "?genToken=" + genToken + "&item=" + id_item, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('item detail ok', responseJson);
                if (responseJson.status === "ITEM_DETAIL_OK")
                    resolve(responseJson.item);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                reject("API_ERROR");
                console.error(e.message);
            });
    });
};

let sendCheckout = function (genToken, formObject) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.sendCheckout + "?genToken=" + genToken, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formObject)
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('order sent', responseJson);
                if (responseJson.status === "ORDER_SENT_OK")
                    resolve(responseJson.session_url);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                reject("API_ERROR");
                console.error(e.message);
            });
    });
};

let getBooking = function (bookingToken) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.getBooking + "?bookingToken=" + bookingToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('booking detail ok', responseJson);
                if (responseJson.status === "BOOKING_DETAIL_OK")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                reject("API_ERROR");
                console.error(e.message);
            });
    });
};

const BookingAPI = {
    signVisit: signVisit,
    getMap: getMap,
    getItem: getItem,
    sendCheckout: sendCheckout,
    getBooking: getBooking
};

module.exports = BookingAPI;
