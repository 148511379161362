import React, {useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import GoogleMapReact from "google-map-react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Loading} from "custom";
import {BookingAPI} from "api/index";
import MobileFooterSticky from "./MobileFooterSticky";
import ModalSelectDate from "../../components/ModalSelectDate";
import converSelectedDateToString from "../../utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Logo from "../../shared/Logo/Logo";

// import AddIcon from "@mui/material/";

// import Add from "@mui/icons-material/Add";
// import Remove from '@mui/icons-material/Remove';
import { red, green } from '@mui/material/colors';

import {Utils} from "custom"

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Alert,
    CircularProgress,
    Chip, Avatar, CardContent, Card, CardHeader, useMediaQuery, useTheme, IconButton
} from "@mui/material";
import ModalSelectItem from "../../components/ModalSelectItem";
import MapRangeInput from "./MapRangeInput";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";
import {PencilSquareIcon} from "@heroicons/react/24/outline";

// import asset from "*.png";
// import HeroSearchForm2MobileFactory from "../../components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

const moment = require("moment")

interface SVGComponentProps {
    lat: number;
    lng: number;
    svg: {
        name: string,
        rotation: number,
        scale: {
            px: number,
            zoom: number
        },
        zIndex: number
    };
}

interface SVGItemComponentProps {
    id: number;
    lat: number;
    lng: number;
    element: {
        label: string,
        svg: string;
        busy: boolean;
        rotation: number,
        scale: {
            px: number,
            zoom: number
        },
        zIndex: number
    };
}

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const MapPage: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [loadingMap, setLoadingMap] = useState(true);

    const [firstStart, setFirstStart] = useState(true);

    const [elements, setElements] = useState([]);
    const [items_available, setItemsAvailable] = useState([]);
    const [items_busy, setItemsBusy] = useState([]);

    const [svgItemSelected, setSvgItemSelected] = useState(null);

    const [beachResort, setBeachResort] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dialogItem, setDialogItem] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [selectedItemForm, setSelectedItemForm] = useState(null);

    const navigate = useNavigate();
    const {genToken} = useParams();

    const [zoom, setZoom] = useState(19);

    const [bill, setBill] = useState(null);



    const [formOrder, setFormOrder] = useState({
        optionals: [],
        bill_frontend: 0,
        booking: {
            id_item: null,
            from_date: null,
            to_date: null,
            payment_intent: null,
            order_note: ""
        },
        client: {
            firstname: "",
            lastname: "",
            email: "",
            cell: ""
        }
    });

    const [mapOption, setMapOption] = useState({
        defaultCenter: {lat: 42.456913978027806, lng: 14.209223827068959},
        heading: 0,
        defaultZoom: 10,
        latLngBounds: {east: null, north: null, south: null, west: null}
    });

    useEffect(() => {
        getMap(null, null);
    }, []);


    const getMap = (startDateQuery, endDateQuery) => {
        setLoadingMap(true);

        startDateQuery = (moment(startDateQuery, "YYYY-MM-DD").isValid()) ? startDateQuery : null
        endDateQuery = (moment(endDateQuery, "YYYY-MM-DD").isValid()) ? endDateQuery : null

        let metaTrack = global.getMetaTrask();

        BookingAPI.getMap(genToken, startDateQuery, endDateQuery, metaTrack).then(async ({map, beachResortMap, orderForm}) => {
            setMapOption((preOptions) => {
                return {
                    defaultCenter: {
                        lat: (map.items_available.length > 1) ? map.mapOption.defaultCenter.lat : map.items_available[0].lat,
                        lng: (map.items_available.length > 1) ? map.mapOption.defaultCenter.lng : map.items_available[0].lng
                    },
                    heading: map.mapOption.heading,
                    defaultZoom: map.mapOption.defaultZoom,
                    latLngBounds: {
                        east: map.mapOption.latLngBounds.east,
                        north: map.mapOption.latLngBounds.north,
                        south: map.mapOption.latLngBounds.south,
                        west: map.mapOption.latLngBounds.west
                    }
                };
            });

            setElements(() => map.elements);

            setItemsAvailable(() => map.items_available);
            setItemsBusy(() => map.items_busy);

            setStartDate(map.start_date)
            setEndDate(map.end_date)

            setBeachResort(beachResortMap)

            let formOrderToEdit = formOrder;

            let item_selected

            if (orderForm) {
                item_selected = map.items_available.find((i) => i.id === orderForm.booking.id_item);
                if (item_selected) {

                    let itemSelectedDetail = await BookingAPI.getItem(genToken, orderForm.booking.id_item);

                    setBill(orderForm.bill_frontend)
                    setSelectedItemForm(itemSelectedDetail);
                    setSvgItemSelected(() => item_selected);

                    formOrderToEdit = {
                        optionals: orderForm.optionals,
                        bill_frontend: orderForm.bill_frontend,
                        booking: orderForm.booking,
                        client: orderForm.client
                    }
                } else {
                    formOrderToEdit.booking.from_date = map.start_date
                    formOrderToEdit.booking.to_date = map.end_date
                }
            } else {
                formOrderToEdit.booking.from_date = map.start_date
                formOrderToEdit.booking.to_date = map.end_date
            }

            setFormOrder(() => formOrderToEdit)

            setLoading(false);

            if (orderForm && item_selected)
                return;

            if (svgItemSelected) {
                selItem(svgItemSelected.id)
            }

            if (!firstStart) {
                setLoadingMap(false);
            }
        }).catch((err) => {
            console.log(err)

            if (window.history.state && window.history.state.idx > 0) {
                window.history.back()
            } else {
                navigate('/', { replace: true });
            }
        });
    };

    const handleChangeDate = (dates) => {
        getMap(moment(dates.startDate).format("YYYY-MM-DD"), moment(dates.endDate).format("YYYY-MM-DD"));
    };


    const confirmSelectItem = (form) => {

        let totalPriceCalculated = Utils.calcBookingPriceOptional(form, selectedItem.optionals) + selectedItem.price;

        let commissionClient = Math.floor((totalPriceCalculated) * beachResort.commission_client_percentage/100) + 0.99

        totalPriceCalculated += commissionClient;
        setBill(totalPriceCalculated)
        setDialogItem(false);
        setSelectedItemForm(selectedItem);
        setSelectedItem(null);

        let item_selected = items_available.find((i) => i.id === selectedItem.id)

        if (item_selected) {
            setSvgItemSelected(() => item_selected);
            let formOrderToEdit = formOrder;
            formOrderToEdit.optionals = form;
            formOrderToEdit.booking.id_item = item_selected.id
            formOrderToEdit.bill_frontend = totalPriceCalculated;
            setFormOrder(() => formOrderToEdit)
        }
    };


    const selItem = (id_item) => {

        if (id_item === null)
            return;

        setDialogItem(true);

        BookingAPI.getItem(genToken, id_item).then(async (itemDetail) => {
            setSelectedItem(itemDetail)
        }).catch((err) => {
            setSelectedItem(false)
            console.error(err)
            if (window.history.state && window.history.state.idx > 0) {
                window.history.back()
            } else {
                navigate('/', { replace: true });
            }
        });
    };

    const onChangeMap = (e) => {
        setZoom(e.zoom);
    };

    const handleApiLoaded = async (map, maps) => {
        const bounds = {
            north: mapOption.latLngBounds.north,
            south: mapOption.latLngBounds.south,
            east: mapOption.latLngBounds.east,
            west: mapOption.latLngBounds.west,
        };
        map.setOptions({
            restriction: {
                latLngBounds: bounds,
                strictBounds: true,
            },
        });
        if (firstStart) {
            setFirstStart(false);
            await wait(1250);
        }

        setLoadingMap(false);
    };

    const closeCheckout = (status) => {
        if (status === "ITEM")
            selItem(selectedItemForm.id)
    }

    const useCalculateDimension = (initialDimension, initialZoom, targetZoom) => {
        return useMemo(() => {
            const zoomFactor = targetZoom - initialZoom;
            const dimensionFactor = Math.pow(2, zoomFactor);
            return initialDimension * dimensionFactor;
        }, [initialDimension, initialZoom, targetZoom]);
    };

    const SVGComponent: React.FC<SVGComponentProps> = React.memo(({svg}) => {
        const dimension = useCalculateDimension(svg.scale.px, svg.scale.zoom, zoom);

        let asset = require("../../assets/image_not_found.jpg");

        try {
            asset = require("../../assets/assetsSVG/" + svg.name + ".png");
        } catch (e) {
            console.log("ERROR");
        }

        return (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: `translate(-50%, -50%) rotate(${svg.rotation}deg)`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: svg.zIndex
                }}
            >
                <img alt="" src={asset} width={`${dimension}px`} height={"100%"}/>
            </div>
        );
    });


    const ItemComponent: React.FC<SVGItemComponentProps> = React.memo(({id, element}) => {

        const dimension = useCalculateDimension(element.scale.px, element.scale.zoom, zoom);

        let asset = useMemo(() => {
            let tmpAsset;
            try {
                if (id === formOrder.booking.id_item && !element.busy) {
                    tmpAsset = require("../../assets/assetsSVG/" + element.svg + "-busy.png");
                } else {
                    tmpAsset = require("../../assets/assetsSVG/" + element.svg + ".png");
                }
            } catch (e) {
                console.log("ERROR");
                tmpAsset = require("../../assets/assetsSVG/not_found.png");
            }
            return tmpAsset;
        }, [element.svg, formOrder]);


        return <div
            onClick={() => selItem(id)}
            title={(element.busy) ? "La postazione è occupata" : ("Seleziona la postazione " + element.label)}
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%) rotate(" + element.rotation + "deg)",
                cursor: (!element.busy) ? "pointer" : "not-allowed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: element.zIndex
            }}
        >
            <img alt="" src={asset} width={`${dimension}px`} height={"100%"}/>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) rotate(" + (-element.rotation) + "deg)",
                    maxWidth: `${dimension * 0.4}px`,
                    maxHeight: `${dimension * 0.4}px`,
                    width: `${dimension * 0.8}px`,
                    height: `${dimension * 0.8}px`,
                    backgroundColor: (element.busy ? '#C62828' : "#1B5E20"),
                    color: (element.busy ? '#ffe3e3' : "#e9ffe9"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: `${dimension * 0.4}px`,
                    zIndex: 10000,
                }}>

                <span style={{fontSize: `${dimension * 0.25}px`, whiteSpace: "nowrap"}}><b>{element.label}</b></span>
            </div>
        </div>
    });

    let days = moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "days") + 1;

    let daysLabel = (days === 1) ? "Prezzo intera Giornata" : ("Prezzo per " + days + " giorni")

    return (
        <div className="overflow-hidden relative" style={{height: '100vh'}}>
            <Helmet>
                <title>Conferma Prenotazione - Easy Beach</title>
            </Helmet>

            <div className="block fixed top-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40 opacity-95">
                <div className="container flex items-center justify-center">
                    <Logo />
                </div>
            </div>

            <BgGlassmorphism/>

            {loading ? <Loading/> : (
                <div style={{width: '100%', height: '100vh'}}>
                    {loadingMap && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: (firstStart) ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 10000,
                        }}>
                            <BgGlassmorphism/>
                            <Loading/>
                        </div>
                    )}

                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_SERVICES_KEY}}
                        defaultCenter={{
                            lat: mapOption.defaultCenter.lat,
                            lng: mapOption.defaultCenter.lng,
                        }}
                        onChange={onChangeMap}
                        defaultZoom={mapOption.defaultZoom}
                        options={{
                            maxZoom: 50,
                            gestureHandling: "greedy",
                            rotateControl: false,
                            tilt: 0,
                            heading: mapOption.heading,
                            streetViewControl: false,
                            fullscreenControl: false,
                            mapTypeControl: false,
                            zoomControl: true,
                            mapId: "18803f0bc6cb01dd",
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                    >

                        {items_available.map((svg, i) => {
                            return <ItemComponent
                                key={"itemAv-" + i}
                                lat={svg.lat}
                                lng={svg.lng}
                                id={svg.id}
                                element={{
                                    label: svg.name,
                                    svg: svg.svg,
                                    busy: false,
                                    rotation: svg.rotation,
                                    scale: svg.scale,
                                    zIndex: svg.zIndex
                                }}
                            />
                        })}

                        {items_busy.map((svg, i) => {
                            return <ItemComponent
                                key={"itemBs-" + i}
                                lat={svg.lat}
                                lng={svg.lng}
                                id={null}
                                element={{
                                    label: svg.name,
                                    svg: svg.svg,
                                    busy: true,
                                    rotation: svg.rotation,
                                    scale: svg.scale,
                                    zIndex: svg.zIndex
                                }}
                            />
                        })}

                        {elements.map((svg, i) => (
                            <SVGComponent
                                key={'element-' + i}
                                lat={svg.lat}
                                lng={svg.lng}
                                svg={{
                                    name: svg.name,
                                    rotation: svg.rotation,
                                    scale: svg.scale,
                                    zIndex: svg.zIndex
                                }}
                            />
                        ))}
                    </GoogleMapReact>

                    <div className="hidden lg:block flex-grow mt-14 lg:mt-0" style={{position: "absolute", maxWidth: "300px", top: 90, right: 20, backgroundColor: "white", opacity: 0.99, borderRadius: 20}}>
                        <div className="sticky top-28">
                            <div className="listingSectionSidebar__wrap ">
                                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                                    <MapRangeInput
                                        className="flex-1 z-[11]"
                                        dates={{startDate: startDate, endDate: endDate}}
                                        setDates={handleChangeDate}
                                    />
                                </form>
                                {(svgItemSelected) ? <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                                    <button
                                        type="button"
                                        className="text-left flex-1 p-5 flex justify-between space-x-3 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                                        onClick={() => selItem(svgItemSelected.id)}
                                    >
                                        <div className="flex flex-col">
                                            <span className="text-sm text-neutral-400">Palma 2° fila</span>
                                            <span className="mt-1.5 text-lg font-semibold">
                                              <span className="line-clamp-1">
                                                n. {svgItemSelected.name}
                                              </span>
                                            </span>
                                        </div>
                                        <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                                    </button>
                                </div> : null}

                                {(bill && svgItemSelected) ? <div className="flex flex-col">
                                    <span className="text-base font-normal text-neutral-500">
                                        {daysLabel}
                                    </span>
                                    <span className="text-3xl font-semibold">
                                        {Utils.getValueMoneyLabel(bill)}
                                    </span>
                                </div> : <div className="flex flex-col">
                                    <span className="text-base font-normal text-neutral-500">
                                        Seleziona una postazione libera sulla mappa
                                    </span>
                                </div>}

                                {(svgItemSelected) ? <ModalReserveMobile
                                    formOrder={formOrder}
                                    setFormOrder={setFormOrder}
                                    selectedItemForm={selectedItemForm}
                                    beachResort={beachResort}
                                    closeCheckout={closeCheckout}
                                    renderChildren={({ openModal }) => (
                                        <ButtonPrimary
                                            sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                                            onClick={openModal}
                                        >
                                            Conferma
                                        </ButtonPrimary>
                                    )}
                                /> : null}
                            </div>
                        </div>
                    </div>

                </div>
            )}
            <MobileFooterSticky
                closeCheckout={closeCheckout}
                daysLabel={daysLabel}
                formOrder={formOrder}
                dates={{startDate: startDate, endDate: endDate}}
                setDates={handleChangeDate}
                selectedItemForm={selectedItemForm}
                beachResort={beachResort}
                setFormOrder={setFormOrder}
            />

            <ModalSelectItem days={days} beachResort={beachResort} dialogItem={dialogItem} selectedItem={selectedItem} setDialogItem={setDialogItem} setSelectedItem={setSelectedItem} confirmSelectItem={confirmSelectItem}/>

        </div>
    );
};

export default MapPage;
