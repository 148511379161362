import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  disabled?: boolean;
}

const ButtonSubmit: FC<Props> = ({ href = "#", onClick, disabled }) => {
  return (
      <Link
          to={disabled ? "#" : href}
          type="button"
          onClick={onClick}
          className={`h-14 md:h-16 w-full md:w-16 rounded-full flex items-center justify-center text-neutral-50 focus:outline-none ${
              disabled ? "bg-gray-400 cursor-not-allowed" : "bg-primary-6000 hover:bg-primary-700"
          }`}
      >
        <span className="mr-3 md:hidden">Cerca</span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
          <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </Link>
  );
};

export default ButtonSubmit;
