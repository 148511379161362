import React, { FC, ReactNode } from "react";
import imagePng from "images/hero-right.png";
import StaySearchForm from "../HeroSearchForm/(stay-search-form)/StaySearchForm";
import logo from "../../images/logo.png";
import { SearchTab } from "components/HeroSearchForm/HeroSearchForm";

import {Skeleton} from "@mui/material";
export interface SectionHeroArchivePageProps {
    className?: string;
    listingType?: ReactNode;
    currentPage: "Beach" | "Experiences" | "Boat";
    currentTab: SearchTab;
    rightImage?: string;
    searchParams: {
        slugTipology: string;
        slugLocality: string;
        startDate: Date | null;
        endDate: Date | null;
        localityCity: string;
        localityForm: string;
    };
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
         className = "",
         listingType,
         currentPage,
         currentTab,
         rightImage = imagePng,
         searchParams,
     }) => {
    return (
        <div
            className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
            data-nc-id="SectionHeroArchivePage"
        >
            <div className="flex flex-col lg:flex-row lg:items-center">
                <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-10 lg:pb-40">
                    <div className="flex-grow mb-16">
                        <img className="w-full" src={logo} alt="hero" />
                    </div>
                </div>
            </div>

            <div className="hidden lg:flow-root w-full">
                <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">
                    {(searchParams) ? <StaySearchForm searchParams={searchParams} /> : <Skeleton variant="rectangular" height="80px" width="100%" sx={{borderRadius: 10, marginTop: 7}}/>}
                </div>
            </div>
        </div>
    );
};

export default SectionHeroArchivePage;
