import React, { Fragment, useState, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

export interface StayDatesRangeInputProps {
  className?: string;
  startDateParams?: any;
  endDateParams?:any;
  location_slug?: any;
  slug_structure?: any;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  startDateParams,
  endDateParams,
  location_slug,
  slug_structure
}) => {
  const [startDate, setStartDate] = useState<Date | null>((startDateParams) ? new Date(startDateParams) : null);

  const [endDate, setEndDate] = useState<Date | null>((endDateParams) ? new Date(endDateParams) : null);

  const navigate = useNavigate();


  const buildUrlTrackParams = () => {
    const getURLParameters = (url) => {
      const params = new URLSearchParams(url.search);
      let paramsObj = {};
      const allowedParams = ['fbclid', 'utm_source', 'utm_medium', 'utm_campaign'];

      params.forEach((value, key) => {
        if (allowedParams.includes(key)) {
          paramsObj[key] = value;
        }
      });
      return paramsObj;
    };

    const currentURL = new URL(window.location.href);
    const paramsObj = getURLParameters(currentURL);

    const newParams = new URLSearchParams(paramsObj);
    return newParams.toString();
  };

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      let url = `/stabilimenti-balneari/${location_slug}/${slug_structure}`;
      const startDateStr = moment(start).format("YYYY-MM-DD");
      const endDateStr = moment(end).format("YYYY-MM-DD");

      if (startDateStr !== null || endDateStr !== null) {
        url += `?start_date=${startDateStr}&end_date=${endDateStr}&${buildUrlTrackParams()}`;
      }

      navigate(url, {replace: true});
    }

    if (start === null && end === null) {
      let url = `/stabilimenti-balneari/${location_slug}/${slug_structure}&${buildUrlTrackParams()}`;

      navigate(url, {replace: true});
    }
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          {(startDate && endDate) ? <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("it-IT", {
              month: "short",
              day: "2-digit",
            }) || "Seleziona Date"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("it-IT", {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span> : <strong>Seleziona le Date</strong>}
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? "shadow-lg" : ""
            }`}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  locale="it"
                  minDate={moment().toDate()}
                  maxDate={moment().endOf("year").toDate()}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
