import { MapPinIcon, MagnifyingGlassIcon, ClockIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { GeoAPI } from "api"; // Assumendo che tu abbia un modulo GeoAPI per la ricerca
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
    onChange?: (value: string) => void;
    className?: string;
    defaultValue?: string;
    headingText?: string;
}

interface SearchResult {
    name: string;
    province?: string;
    slug: string;
}

const LocationInput: FC<Props> = ({
                                      onChange = () => {},
                                      className = "",
                                      defaultValue = "Italia",
                                      headingText = "Dove andiamo?",
                                  }) => {
    const [value, setValue] = useState(defaultValue);
    const [showPopover, setShowPopover] = useState(false);
    const [recentSearches, setRecentSearches] = useState<SearchResult[]>([]);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [loading, setLoading] = useState(false);

    const containerRef = useRef(null);
    const inputRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        const storedSearches = localStorage.getItem("recentSearches");
        if (storedSearches) {
            setRecentSearches(JSON.parse(storedSearches));
        }
    }, []);

    useEffect(() => {
        if (showPopover && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showPopover]);

    const handleSelectLocation = (item: SearchResult) => {
        const displayValue = `${item.name} ${item.province || ""}`;
        setTimeout(() => {
            setValue(displayValue);
            onChange(item.slug);

            const searchParams = new URLSearchParams(location.search);
            searchParams.set("q", item.slug);
            navigate(`${location.pathname}?${searchParams.toString()}`);

            setShowPopover(false);

            console.log({ slug: item.slug, province: item.province, name: item.name });

            const newRecentSearches = [
                { slug: item.slug, province: item.province, name: item.name },
                ...recentSearches.filter((search) => search.slug !== item.slug),
            ].slice(0, 5);

            setRecentSearches(newRecentSearches);
            localStorage.setItem("recentSearches", JSON.stringify(newRecentSearches));

        }, 0);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setValue(newValue);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                setLoading(true);
                fetchSearchResults(newValue);
            }, 1000)
        );
    };

    const fetchSearchResults = (query: string) => {
        if (!query) {
            setLoading(false);
            return;
        }

        GeoAPI.autocomplete(query)
            .then((predictions) => {
                setSearchResults(predictions);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const renderSearchValues = ({
                                    heading,
                                    items,
                                }: {
        heading: string;
        items: SearchResult[];
    }) => {
        return (
            <>
                <p className="block font-semibold text-base">{heading || "Destinazioni"}</p>
                <div className="mt-3">
                    {items.map((item) => (
                        <div
                            className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                            onClick={() => handleSelectLocation(item)}
                            key={item.slug}
                        >
                            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                            <span>{`${item.name} ${item.province || ""}`}</span>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    const renderRecentSearches = () => {
        return (
            <>
                <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
                    Ricerche recenti
                </h3>
                <div className="mt-2">
                    {recentSearches.map((item) => (
                        <span
                            onClick={() => handleSelectLocation(item)}
                            key={item.slug}
                            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                        >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {`${item.name} ${item.province || ""}`}
              </span>
            </span>
                    ))}
                </div>
            </>
        );
    };

    const renderSearchResults = () => {
        if (loading) {
            return (
                <div className="flex justify-center items-center py-4">
                    <CircularProgress />
                </div>
            );
        }

        return (
            <>
                {searchResults.map((item, index) => (
                    <span
                        onClick={() => handleSelectLocation(item)}
                        key={index}
                        className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                    >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <div className="block">
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">{item.name}</span>
              <span className="block text-sm text-neutral-500 dark:text-neutral-400">{item.province}</span>
            </div>
          </span>
                ))}
            </>
        );
    };

    return (
        <div className={`${className}`} ref={containerRef}>
            <div className="p-5">
                <span className="block font-semibold text-xl sm:text-2xl">{headingText}</span>
                <div className="relative mt-5">
                    <input
                        className="block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate"
                        placeholder="Cerca destinazioni"
                        value={value}
                        onChange={handleInputChange}
                        ref={inputRef}
                        onClick={() => setShowPopover(true)}
                    />
                    <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
                </div>
                <div className="mt-7">
                    {showPopover && (value ? renderSearchResults() : renderRecentSearches())}
                </div>
            </div>
        </div>
    );
};

export default LocationInput;
