import React from 'react';

const PrivacyPage: React.FC = () => {

    require("./TermsPage.css")
    return (
        <div className="terms-container">
            <h1>Privacy Policy di Easy Beach</h1>

            <h2>1. Premessa</h2>
            <p>
                La presente informativa sul trattamento dei dati personali ("Privacy Policy") è resa ai sensi del Regolamento UE 679/2016 ("GDPR"), del D.Lgs.196/2003 (e successive modifiche), della Direttiva 2002/58/CE ("Direttiva e-Privacy") e delle altre disposizioni e provvedimenti applicabili in materia di privacy e protezione dei dati personali ("Leggi in materia di protezione dei dati personali"), per informarti dei trattamenti dei dati personali effettuati tramite il sito web easybeach.online ("Sito Web"), l'applicazione ("App") ovvero mediante Widget (il Sito Web, l'App e il Widget saranno di seguito congiuntamente chiamati la "Piattaforma"), al fine di consentirti di usufruire dei servizi di prenotazione offerti dalla Piattaforma ("Servizi") secondo quanto meglio descritto nelle Condizioni Generali di prenotazione su Easy Beach. Prima di comunicarci qualsiasi dato personale, ti invitiamo, pertanto, a leggere attentamente la presente Informativa perché contiene importanti informazioni sulla tutela dei tuoi dati personali.
            </p>

            <p>
                L'informativa è resa esclusivamente per la Piattaforma e non anche per altri siti web eventualmente consultati tramite link. La presente informativa non si applica al trattamento dei dati effettuati dagli Stabilimenti Balneari tramite la Piattaforma o in relazione alla stessa in qualità di titolari autonomi, che è disciplinato dalle rispettive informative sulla privacy.
            </p>

            <p>
                I termini della presente Privacy Policy qui non definiti, avranno il significato indicato nelle Condizioni Generali di prenotazione su Easy Beach.
            </p>

            <h2>2. Minori</h2>

            <p>
                I Servizi sono rivolti a persone di età non inferiore a 18 anni. Con l'utilizzo e la registrazione alla Piattaforma, gli Utenti dichiarano di aver raggiunto la maggiore età in base alla legislazione vigente. Le persone di età inferiore ad anni 18 possono utilizzare la Piattaforma solo con l'assistenza di un genitore o tutore. Qualora il titolare del trattamento dei dati personali venisse a conoscenza del fatto che un minore di 18 anni ha fornito dati personali, adotterà misure ragionevoli per rimuovere tali informazioni dai sistemi e chiudere il relativo account, senza assunzione di alcuna responsabilità in relazione a tali dati e al loro uso.
            </p>

            <h2>3. Definizioni</h2>

            <p><strong>Dati Personali (o Dati):</strong> costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
            <p><strong>Interessato:</strong> la persona fisica cui si riferiscono i Dati Personali.</p>
            <p><strong>Responsabile del Trattamento (o Responsabile):</strong> la persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del titolare del trattamento, secondo quanto esposto nella presente Privacy Policy.</p>
            <p><strong>Titolare del Trattamento (o Titolare):</strong> la persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione della Piattaforma.</p>
            <p><strong>Utente:</strong> l'individuo che utilizza la Piattaforma che, salvo ove diversamente specificato, coincide con l'Interessato.</p>


            <h2>4. Titolare del Trattamento</h2>
            <p>
                FB Web Studio, società di diritto italiano iscritta al Registro delle Imprese di Pescara con sede legale in Via Campo Felice, 16, Pescara, Italia, P. IVA n. IT02322060688, email support@easybeach.cloud, in persona del legale rappresentante, è il Titolare del trattamento dei dati forniti dall'Utente persona fisica durante le interazioni con la Piattaforma.
            </p>

            <h2>5. Tipologia dei Dati Trattati</h2>
            <p>
                Quando utilizzi la Piattaforma, Easy Beach può raccogliere alcuni dei tuoi Dati Personali che, a seconda dei casi, possono consistere in dati che ti identificano (come il tuo nome), oppure in dati idonei a renderti identificabile. I tuoi dati personali possono essere raccolti sia perché li fornisci volontariamente (per esempio quando richiedi informazioni attraverso la Piattaforma) sia analizzando il tuo comportamento mentre interagisci con i nostri Servizi.
            </p>

            <p>
                L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante la Piattaforma e garantisce di avere il diritto di comunicarli o diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
            </p>

            <p>
                I Dati Personali trattati tramite la Piattaforma sono i seguenti:
            </p>


            <p><strong>Dati personali comuni:</strong> Informazioni di contatto (come, ad esempio, nome e indirizzo e-mail), identificativi e informazioni sull'attività su Internet e sulla rete (come, ad esempio, indirizzo IP, modello del dispositivo, tipo di dispositivo, versione del sistema operativo, lingua del dispositivo, nome del dispositivo, paese impostato nelle impostazioni del dispositivo, informazioni sulle interazioni dell'utente con l'applicazione e dati identificativi unici, compresi IDFA o AAID) e altre informazioni necessarie per consentire all'Utente di utilizzare la Piattaforma.</p>
            <p><strong>Dati di prenotazione dei Servizi Balneari:</strong> Quando utilizzi i Servizi della Piattaforma senza registrarti, riceveremo le seguenti informazioni: nome, cognome, indirizzo e-mail, telefono, dati per la fatturazione (città, ragione sociale, indirizzo, PEC o codice destinatario, P.IVA e/o C.F.).</p>
            <p><strong>Dati di registrazione alla Piattaforma:</strong> Quando crei un account sulla Piattaforma, riceveremo le seguenti informazioni: nome, cognome, indirizzo e-mail, numero di telefono.</p>
            <p><strong>Dati per richiedere informazioni su Easy Beach:</strong> Se desideri chiedere informazioni, raccoglieremo i seguenti dati: nome, cognome, e-mail, telefono.</p>
            <p><strong>Dati di pagamento:</strong> Se utilizzi i Servizi della Piattaforma a pagamento, i dati di pagamento sono necessari per acquistare i Servizi offerti da Easy Beach. Tali Dati Personali sono gestiti da fornitori dei servizi di pagamento di cui il Titolare si avvale (Stripe Inc e Paypal); Easy Beach non è dunque coinvolta nella raccolta e nell'elaborazione di tali informazioni ma riceverà solo una notifica da parte del fornitore di servizi di pagamento in questione circa l'avvenuto pagamento.</p>
            <p><strong>Dati raccolti automaticamente:</strong> Identificativi e informazioni sull'attività di Internet e della rete (come, ad esempio, l'indirizzo IP, il modello e il tipo di dispositivo, la versione del sistema operativo, i crash e gli errori), le informazioni raccolte o generate per migliorare le funzionalità della Piattaforma (come, ad esempio, la lingua del dispositivo e le informazioni sulle vostre interazioni con l'app), le informazioni sulla vostra posizione (come, ad esempio, l'indirizzo IP o GPS del vostro dispositivo mobile), le inferenze che generiamo e altre informazioni relative al vostro uso della Piattaforma.</p>
            <p><strong>Dati di navigazione: </strong> I sistemi informatici e le procedure software preposte al funzionamento della Piattaforma acquisiscono, nel corso del loro normale esercizio, alcuni Dati Personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli Utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli Utenti che si connettono alla Piattaforma, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso della Piattaforma e per controllarne il corretto funzionamento, per consentire la corretta erogazione dei servizi, per motivi di sicurezza e per l'accertamento di responsabilità in caso di ipotetici reati informatici ai danni della Piattaforma o di terzi; e vengono normalmente cancellati dopo sessanta giorni.</p>

            <h2>6. Finalità del Trattamento</h2>

            <p>
                Utilizzeremo i tuoi Dati Personali, raccolti attraverso la Piattaforma, per i seguenti scopi:
            </p>

            <ul>
                <li>Fornire i Servizi richiesti, come ad esempio, registrarti e accedere alla Piattaforma, prenotare il Servizio Balneare, fornirti supporto e rispondere alle tue richieste di assistenza, contattarti dopo la richiesta di informazioni e supportarti nel processo di acquisto dei nostri Servizi o per risolvere eventuali problemi che hai riscontrato, inviarti la newsletter di Easy Beach che hai richiesto come servizio ("Fornitura dei Servizi")</li>
                <li>Inviarti comunicazioni di marketing, promozioni e pubblicità, ricerche di mercato e sondaggi, via e-mail, SMS, notifiche push, per telefono, tramite banner, posta cartacea, messaggistica istantanea, tramite un operatore, attraverso le pagine ufficiali dei social media di Easy Beach, nonché per attività di marketing in senso lato, comprese manifestazioni a premio, giochi e concorsi, di prodotti e/o servizi riferibili a Easy Beach o a terzi ("Marketing")</li>
                <li>Inviarti comunicazioni di marketing via e-mail riguardanti prodotti e servizi simili a quelli che hai acquistato ("Soft Spam")</li>
                <li>Creare il tuo profilo raccogliendo e analizzando le informazioni sulle tue attività all'interno della Piattaforma ("Profilazione")</li>
                <li>Analizzare, sviluppare e migliorare i nostri prodotti e servizi attraverso l'aggregazione dei dati di utilizzo e le vostre interazioni con la Piattaforma ("Analisi statistica")</li>
                <li>Rispettare gli obblighi legali che impongono a Easy Beach di raccogliere e trattare ulteriormente alcuni tipi di Dati Personali ("Adempimento obblighi legali")</li>
                <li>Controllare come viene utilizzata la Piattaforma, prevenire o identificare eventuali abusi o eventuali attività fraudolente a danno di Easy Beach e quindi permettere a Easy Beach di tutelarsi anche in sede giudiziaria ("Difesa")</li>
            </ul>

            <p>
                Easy Beach utilizza strumenti di analisi, come Hotjar, per monitorare il comportamento degli utenti sulla Piattaforma al fine di migliorare l'esperienza utente e ottimizzare i nostri servizi. Hotjar raccoglie dati di utilizzo, come movimenti del mouse, clic, e tempo trascorso sulle pagine, che vengono utilizzati per analizzare e migliorare le prestazioni del sito. Tali dati vengono trattati in forma aggregata e anonima. La base giuridica per questo trattamento è il legittimo interesse di Easy Beach a comprendere come gli utenti interagiscono con la Piattaforma e a migliorarla di conseguenza.
            </p>


            <h2>7. Base Giuridica e Natura del Trattamento</h2>

            <p><strong>Fornitura dei Servizi:</strong> il trattamento per questa finalità è necessario per poterti fornire i nostri Servizi e, quindi, per l'esecuzione di misure precontrattuali o contrattuali da te richieste. Non è obbligatorio fornire a Easy Beach i tuoi Dati Personali per questa finalità, ma in caso contrario non sarà possibile fornire alcun servizio.</p>
            <p><strong>Marketing:</strong> il trattamento per questa finalità si basa sul tuo consenso. Non è obbligatorio dare il tuo consenso a Easy Beach per questa finalità e sei libero di ritirarlo in qualsiasi momento senza alcuna conseguenza (salvo il fatto che non riceverai più comunicazioni di marketing da Easy Beach).</p>
            <p><strong>Soft Spam:</strong> il trattamento per questa finalità si basa sul legittimo interesse di Easy Beach ad inviarti comunicazioni di marketing via e-mail relative a prodotti e servizi simili a quelli che hai già acquistato. Puoi interrompere la ricezione di queste comunicazioni, senza alcuna conseguenza per te (se non il fatto che non riceverai più ulteriori comunicazioni di questo tipo da parte di Easy Beach) utilizzando il link presente in fondo a ciascuna di queste e-mail.</p>
            <p><strong>Profilazione:</strong> il trattamento per questa finalità si basa sul tuo consenso raccolto attraverso il cookie banner. Non è obbligatorio dare il tuo consenso a Easy Beach per questa finalità e sei libero di ritirarlo in qualsiasi momento senza alcuna conseguenza.</p>
            <p><strong>Analisi statistica:</strong> il trattamento per questa finalità si basa sul legittimo interesse di Easy Beach a migliorare i prodotti e i servizi offerti. È possibile esercitare il diritto di opposizione a tale trattamento come spiegato nella sezione 10.</p>
            <p><strong>Adempimento degli obblighi legali:</strong> il trattamento per questa finalità è necessario a Easy Beach per adempiere ad eventuali obblighi di legge. I tuoi Dati Personali devono essere trattati secondo la legislazione applicabile, il che potrebbe comportare la loro conservazione e comunicazione alle Autorità per obblighi contabili, fiscali o altro.</p>
            <p><strong>Difesa: </strong> il trattamento per questa finalità si basa sul legittimo interesse di Easy Beach a identificare e prevenire eventuali attività fraudolente o abusi nell'utilizzo della Piattaforma e quindi a tutelarsi anche in sede giudiziaria.</p>

            <h2>8. Comunicazione dei Dati Personali</h2>

            <p>
                Per poter fornire i propri Servizi, Easy Beach condividerà i Dati Personali con gli Stabilimenti Balneari presso cui hai effettuato la Prenotazione. I Dati personali condivisi sono i dati forniti dall'Utente per la Prenotazione dei Servizi Balneari.
            </p>

            <p>
                I tuoi Dati Personali possono essere condivisi con i soggetti elencati di seguito:
            </p>

            <ul>
                <li>Soggetti terzi di cui Easy Beach si serve affinché eroghino servizi per conto della stessa (come ad esempio, servizi di analisi web, di remarketing e behavioral targeting, di monitoraggio dell'infrastruttura, di interazione con le piattaforme di live chat, di hosting provider, di gestione dei tag, di gestione dei contatti e l'invio di messaggi); Easy Beach fornisce a tali soggetti solo i dati personali necessari per eseguire le attività concordate e, ove necessario, potrà nominarli responsabili del trattamento dei dati ai sensi del GDPR;</li>
                <li>Persone autorizzate da Easy Beach a trattare i Dati Personali necessari allo svolgimento di attività strettamente connesse alla fornitura dei servizi, che si impegnano alla riservatezza o hanno un adeguato obbligo legale di riservatezza (ad esempio i dipendenti di Easy Beach);</li>
                <li>Autorità pubbliche, giudiziarie o di polizia, nei limiti stabiliti dalla legislazione applicabile.</li>
            </ul>

            <p>
                Previo tuo esplicito e specifico consenso, Easy Beach potrà cedere Dati Personali ad una società che offre una soluzione di Customer Data Platform (ossia una piattaforma che consente di raccogliere e aggregare tutti i dati personali disponibili in un unico database), che li potrà trattare per proprie finalità di marketing.
            </p>

            <h2>9. Modalità di Trattamento dei Dati e Trasferimento di Dati Personali</h2>
            <p>
                Il trattamento dei dati personali può includere qualsiasi tipo di operazione tra cui, la raccolta, la consultazione, l'elaborazione, la modifica, la selezione, l'estrazione, il raffronto, l'utilizzo, la comunicazione, il trasferimento e la cancellazione.
            </p>

            <p>
                I dati personali sono trattati con strumenti automatizzati/digitali su supporti informatici e/o telematici per il tempo strettamente necessario a conseguire le finalità per cui sono stati raccolti e utilizzando database/piattaforme elettroniche gestite dal Titolare.
            </p>

            <p>
                Il Titolare ha adottato soluzioni tecniche e misure di sicurezza che garantiscono la sicurezza dei dati personali ed evitano la loro alterazione, perdita, trattamento non corretto o accesso non autorizzato. I dati personali sono trattati presso la sede del Titolare; per gli Utenti residenti nell'Unione Europea, i dati personali potranno essere trasferiti, trattati e conservati su server dislocati o condivisi con destinatari che potrebbero trovarsi al di fuori dello Spazio Economico Europeo. Easy Beach garantisce che tali trattamenti/trasferimenti dei vostri Dati Personali da parte di tali destinatari avviene nel rispetto della legislazione applicabile. I trasferimenti avvengono infatti tramite garanzie adeguate, quali decisioni di adeguatezza, clausole contrattuali standard approvate dalla Commissione europea o altre garanzie ritenute adeguate. Per maggiori informazioni ti invitiamo a scrivere a support@easybeach.cloud.
            </p>

            <h2>10. Periodo di Conservazione</h2>

            <p>
                I Dati Personali trattati ai fini della Fornitura dei Servizi saranno conservati da Easy Beach per il tempo strettamente necessario alla suddetta finalità. In ogni caso, poiché tali Dati Personali sono trattati per fornirvi i nostri servizi, Easy Beach potrà conservarli per un periodo più lungo, in particolare per quanto necessario a proteggere gli interessi di Easy Beach da eventuali reclami relativi all'uso della Piattaforma.
            </p>

            <p>
                I dati personali trattati per il marketing e la profilazione saranno conservati da Easy Beach fino alla revoca del consenso da parte dell'Utente. Una volta revocato il consenso, Easy Beach non utilizzerà più i tuoi Dati personali per queste finalità, ma potrà comunque conservarli per altre finalità, in particolare per quanto necessario a tutelare gli interessi di Easy Beach da eventuali reclami basati su tale trattamento.
            </p>

            <p>
                I dati personali trattati per il Soft Spam saranno conservati da Easy Beach fino a quando l'Utente non si opporrà a tale trattamento attraverso il link presente in fondo a ciascuna delle e-mail del Soft Spam.
            </p>

            <p>
                I dati personali trattati per l'Adempimento di obblighi contrattuali saranno conservati da Easy Beach per il periodo richiesto da specifici obblighi legali o dalla legislazione applicabile.
            </p>

            <p>
                I Dati Personali trattati per la Difesa saranno conservati da Easy Beach per il tempo strettamente necessario alla suddetta finalità e quindi fino al momento in cui Easy Beach sarà tenuta a conservarli per tutelarsi in giudizio per comunicare tali dati alle Autorità competenti.
            </p>

            <p>
                I dati personali trattati per l'analisi statistica saranno conservati da Easy Beach per il periodo richiesto da specifici obblighi di legge o dalla legislazione applicabile.
            </p>



            <h2>11. Diritti dell'interessato</h2>
            <p>
                Puoi esercitare i seguenti diritti previsti dagli artt. 15 e ss. del GDPR scrivendo all'indirizzo email support@easybeach.cloud:
            </p>

            <ul>
                <li>Ottenere dal Titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che ti riguardano e di conoscere l'origine, la finalità, le categorie di dati trattati, i destinatari a cui sono o saranno comunicati i dati personali anche se all'estero, il tempo di conservazione;</li>
                <li>Ottenere, senza giustificato ritardo, la rettifica dei dati inesatti o l'integrazione;</li>
                <li>Ottenere senza giustificato ritardo, la cancellazione dei dati ai sensi dell'art. 17 GDPR;</li>
                <li>Ottenere la limitazione del trattamento ove sia contestata l'esattezza dei dati o il trattamento è illecito, ai sensi dell'art. 18 GDPR;</li>
                <li>Ottenere la portabilità dei dati mediante la ricezione degli stessi in un formato strutturato;</li>
                <li>Opporti al trattamento ai sensi dell'art. 21 GDPR.</li>
            </ul>

            <p>
                In ogni caso hai sempre diritto di proporre reclamo all'Autorità di Controllo competente (Garante per la Protezione dei Dati Personali), ai sensi dell'art. 77 del GDPR, qualora tu ritenga che il trattamento dei tuoi dati personali sia contrario alla normativa in vigore.
            </p>

            <h2>12. Modifiche alla Privacy Policy</h2>
            <p>
                Possiamo modificare o aggiornare la presente Informativa, anche a causa di variazioni delle Leggi in materia di protezione dei dati personali o qualora dovessimo implementare nuovi elementi o funzionalità che comporteranno il trattamento di dati personali. Ti invitiamo quindi a visitare con regolarità questa pagina per prendere cognizione della più recente ed aggiornata versione di Informativa. La prosecuzione nell'uso della Piattaforma da parte dell'Utente sarà considerata quale accettazione della Privacy Policy come modificata.
            </p>

            <br />
            <small>Ultimo aggiornamento:<br/>agosto 2024</small>
        </div>
    );
};

export default PrivacyPage;
