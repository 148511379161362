import React from 'react';

const CookiePage: React.FC = () => {

    require("./TermsPage.css")
    return (
        <div className="terms-container">
            <h1>Cookie Policy di Easy Beach</h1>
            <p>Questo documento contiene informazioni in merito alle tecnologie che consentono a easybeach.online di
                raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e
                salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio
                eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce con
                easybeach.online.</p>

            <p>Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di
                Tracciamento”, salvo vi sia ragione di differenziare. Per esempio, sebbene i Cookie possano essere usati
                in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per
                dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di
                un browser. Per questo motivo, all’interno di questo documento il termine Cookie è utilizzato solo per
                indicare in modo specifico quel particolare tipo di Strumento di Tracciamento.</p>

            <p>Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre
                richiedere il consenso dell’Utente. Se viene prestato il consenso, esso può essere revocato liberamente
                in qualsiasi momento seguendo le istruzioni contenute in questo documento.</p>

            <p>Easy Beach utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti
                Strumenti di Tracciamento “di prima parte”) e Strumenti di Tracciamento che abilitano servizi forniti da
                terzi (comunemente detti Strumenti di Tracciamento “di terza parte”). Se non diversamente specificato
                all’interno di questo documento, tali terzi hanno accesso ai rispettivi Strumenti di Tracciamento.
                Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili possono variare a seconda di
                quanto impostato dal Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al termine della
                sessione di navigazione dell’Utente. In aggiunta a quanto specificato nella descrizione di ciascuna
                delle categorie di seguito riportate, gli Utenti possono ottenere informazioni più dettagliate ed
                aggiornate sulla durata, così come qualsiasi altra informazione rilevante - quale la presenza di altri
                Strumenti di Tracciamento - nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a
                disposizione) o contattando il Titolare.</p>

            <h2>Misurazione</h2>
            <p>Easy Beach utilizza Strumenti di Tracciamento per misurare il traffico e analizzare il comportamento
                degli Utenti per migliorare il Servizio.</p>

            <h3>Meta Events Manager (Meta Platforms Ireland Limited)</h3>
            <p>Meta Events Manager è un servizio di statistica fornito da Meta Platforms Ireland Limited. Integrando il
                pixel di Meta, Meta Events Manager può dare al Titolare delle informazioni sul traffico e sulle
                interazioni su easybeach.online.</p>
            <p>Dati Personali trattati: clic, ID Utente, pageview e Strumenti di Tracciamento.</p>
            <p>Luogo del trattamento: Irlanda – <a href="https://www.facebook.com/policy.php" target="_blank">Privacy
                Policy</a> – <a href="https://www.facebook.com/ads/preferences" target="_blank">Opt out</a>.</p>
            <p>Durata di archiviazione: _fbp: 3 mesi</p>

            <h2>Pubblicità</h2>
            <p>Alcuni dei servizi di seguito indicati potrebbero utilizzare Strumenti di Tracciamento per identificare
                l’Utente, o utilizzare la tecnica del behavioral retargeting, ossia visualizzare annunci pubblicitari
                personalizzati in base agli interessi e al comportamento dell’Utente, o misurare le prestazioni degli
                annunci. Per avere maggiori informazioni in merito, ti suggeriamo di verificare le informative privacy
                dei rispettivi servizi. Generalmente i servizi di questo tipo offrono la possibilità di disattivare tale
                tracciamento. Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi elencati in
                questo documento, l’Utente può leggere di più su come disattivare gli annunci pubblicitari basati sugli
                interessi nell'apposita sezione "Come disattivare la pubblicità basata sugli interessi" in questo
                documento.</p>

            <h3>Monitoraggio conversioni di Meta ads (pixel di Meta) (Meta Platforms Ireland Limited)</h3>
            <p>Il monitoraggio conversioni di Meta ads (pixel di Meta) è un servizio di statistiche fornito da Meta
                Platforms Ireland Limited che collega i dati provenienti dal network di annunci Meta con le azioni
                compiute all'interno di easybeach.online. Il pixel di Meta monitora le conversioni che possono essere
                attribuite alle inserzioni di Facebook, Instagram e Audience Network.</p>
            <p>Dati Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</p>
            <p>Luogo del trattamento: Irlanda – <a href="https://www.facebook.com/policy.php" target="_blank">Privacy
                Policy</a> – <a href="https://www.facebook.com/ads/preferences" target="_blank">Opt out</a>.</p>
            <p>Durata di archiviazione: _fbp: 3 mesi, fr: 3 mesi</p>

            <h2>Remarketing e behavioral targeting</h2>
            <p>Questo tipo di servizi consente a easybeach.online ed ai suoi partner di comunicare, ottimizzare e
                servire annunci pubblicitari basati sull'utilizzo passato di easybeach.online da parte dell'Utente.
                Questa attività è facilitata dal tracciamento dei Dati di Utilizzo e dall'utilizzo di Strumenti di
                Tracciamento per raccogliere informazioni che vengono poi trasferite ai partner che gestiscono le
                attività di remarketing e di behavioral targeting. Alcuni servizi offrono un'opzione di remarketing
                basata sulle liste di indirizzi email.</p>
            <p>In aggiunta alle funzionalità di opt-out offerte dai servizi di seguito riportati, l'Utente può
                effettuare l’opt-out visitando la pagina di opt-out della Network Advertising Initiative. Gli Utenti
                possono anche scegliere di non partecipare a determinate funzionalità pubblicitarie attraverso le
                corrispondenti opzioni di configurazione del dispositivo, come le opzioni di configurazione
                pubblicitaria del dispositivo mobile o la configurazione pubblicitaria generica.</p>

            <h3>Pubblico personalizzato di Facebook (Facebook Ireland Ltd)</h3>
            <p>Pubblico personalizzato di Facebook è un servizio di remarketing e di targeting comportamentale fornito
                da Facebook Ireland Ltd che collega l'attività di easybeach.online con la rete pubblicitaria di
                Facebook. Gli Utenti possono scegliere di non utilizzare i cookie di Facebook per la personalizzazione
                degli annunci visitando questa pagina di opt-out.</p>
            <p>Dati Personali trattati: email e Strumento di Tracciamento.</p>
            <p>Luogo del trattamento: Irlanda – <a href="https://www.facebook.com/policy.php" target="_blank">Privacy
                Policy</a> – <a href="https://www.facebook.com/ads/preferences" target="_blank">Opt Out</a>.</p>
            <p>Durata di archiviazione: _fbp: 3 mesi</p>

            <h3>Hotjar (Hotjar Ltd.)</h3>
            <p>Hotjar è uno strumento di analisi comportamentale che permette di comprendere meglio l'esperienza di
                navigazione degli utenti su easybeach.online. Hotjar utilizza cookie e altre tecnologie di tracciamento
                per raccogliere dati sui comportamenti degli utenti, come clic, movimenti del mouse, scorrimenti, e
                interazioni generali con il sito.</p>
            <p>Questi dati vengono utilizzati per migliorare la funzionalità e l'usabilità del sito. Le informazioni
                raccolte da Hotjar vengono aggregate e rese anonime, garantendo la protezione della privacy degli
                utenti.Questi dati vengono utilizzati per migliorare la funzionalità e l'usabilità del sito. Le
                informazioni raccolte da Hotjar vengono aggregate e rese anonime, garantendo la protezione della privacy
                degli utenti.</p>

            <p>Gli utenti possono scegliere di disattivare l'uso di Hotjar tramite le impostazioni del proprio browser o visitando la pagina di <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Cookies-on-hotjar-com" target="_blank">opt-out di Hotjar</a>.</p>

            <p>Dati Personali trattati: Dati di utilizzo e Strumenti di Tracciamento.</p>
            <p>Luogo del trattamento: Malta</p>

            <h2>Come gestire le preferenze e prestare o revocare il consenso su easybeach.online</h2>
            <p>Qualora l’utilizzo dei Tracker sia basato sul consenso, l’Utente può fornire o revocare tale consenso
                impostando o aggiornando le proprie preferenze tramite il relativo pannello delle scelte in materia di
                privacy disponibile su easybeach.online.</p>
            <p>Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le proprie
                preferenze visitando il relativo link di opt-out (qualora disponibile), utilizzando gli strumenti
                descritti nella privacy policy della terza parte o contattando quest'ultima direttamente.</p>

            <h2>Come controllare o eliminare Cookie e tecnologie simili tramite le impostazioni del tuo dispositivo</h2>
            <p>Gli Utenti possono utilizzare le impostazioni del proprio browser per:</p>
            <ul>
                <li>Vedere quali Cookie o altre tecnologie simili sono stati impostati sul dispositivo;</li>
                <li>Bloccare Cookie o tecnologie simili;</li>
                <li>Cancellare i Cookie o tecnologie simili dal browser.</li>
            </ul>
            <p>Le impostazioni del browser, tuttavia, non consentono un controllo granulare del consenso per
                categoria.</p>
            <p>Gli Utenti possono trovare informazioni su come gestire i Cookie in alcuni dei browser più diffusi ai
                seguenti indirizzi:</p>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank">Google Chrome</a>
                </li>
                <li><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank">Mozilla
                    Firefox</a></li>
                <li><a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank">Apple
                    Safari</a></li>
                <li><a
                    href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank">Microsoft Internet Explorer</a></li>
                <li><a
                    href="https://support.microsoft.com/it-it/microsoft-edge/browse-inprivate-45fdbf64-4d54-4d26-afaf-25a25626a62a"
                    target="_blank">Microsoft Edge</a></li>
                <li><a href="https://support.brave.com/hc/en-us/articles/360018123951-How-do-I-manage-Cookies-in-Brave-"
                       target="_blank">Brave</a></li>
                <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
            </ul>
            <p>Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili
                disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicità per
                dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare
                le impostazioni del dispositivo per individuare quella pertinente).</p>

            <h2>Come disattivare la pubblicità basata sugli interessi</h2>
            <p>Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle informazioni
                presenti su <a href="https://www.youronlinechoices.com" target="_blank">YourOnlineChoices</a> (UE e
                Regno Unito), <a href="https://www.networkadvertising.org" target="_blank">Network Advertising
                    Initiative</a> (USA) e <a href="https://www.digitaladvertisingalliance.org" target="_blank">Digital
                    Advertising Alliance</a> (USA), <a href="https://youradchoices.ca/"
                                                       target="_blank">DAAC</a> (Canada), <a
                    href="https://www.ddai.info/" target="_blank">DDAI</a> (Giappone) o altri servizi analoghi. Con
                questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti
                pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle
                informazioni fornite nel presente documento.</p>
            <p>La Digital Advertising Alliance mette inoltre a disposizione un’applicazione chiamata <a
                href="https://youradchoices.com/appchoices" target="_blank">AppChoices</a> che aiuta gli Utenti a
                controllare la pubblicità comportamentale sulle applicazioni mobili.</p>

            <h2>Conseguenze legate al rifiuto dell'utilizzo di Strumenti di Tracciamento</h2>
            <p>Gli Utenti sono liberi di decidere se permettere o meno l'utilizzo di Strumenti di Tracciamento.
                Tuttavia, si noti che gli Strumenti di Tracciamento consentono a easybeach.online di fornire agli Utenti
                un'esperienza migliore e funzionalità avanzate (in linea con le finalità delineate nel presente
                documento). Pertanto, qualora l'Utente decida di bloccare l'utilizzo di Strumenti di Tracciamento, il
                Titolare potrebbe non essere in grado di fornire le relative funzionalità.</p>

            <h2>Titolare del Trattamento dei Dati</h2>
            <p>FB Web Studio<br/>
                Via Campo Felice, 16<br/>
                Pescara (PE), Italia<br/>
                Indirizzo email del Titolare: <a href="mailto:support@easybeach.cloud">support@easybeach.cloud</a></p>

            <p>Dal momento che l’uso di Strumenti di Tracciamento di terza parte su easybeach.online non può essere
                completamente controllato dal Titolare, ogni riferimento specifico a Strumenti di Tracciamento di terza
                parte è da considerarsi indicativo. Per ottenere informazioni complete, gli Utenti sono gentilmente
                invitati a consultare la privacy policy dei rispettivi servizi terzi elencati in questo documento.</p>
            <p>Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti sono
                invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito
                all'utilizzo di tali tecnologie su easybeach.online.</p>

            <h2>Definizioni e riferimenti legali</h2>

            <h3>Dati Personali (o Dati)</h3>
            <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in
                collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale,
                renda identificata o identificabile una persona fisica.</p>

            <h3>Dati di Utilizzo</h3>
            <p>Sono le informazioni raccolte automaticamente attraverso easybeach.online (anche da applicazioni di parti
                terze integrate in easybeach.online), tra cui: gli indirizzi IP o i nomi a dominio dei computer
                utilizzati dall’Utente che si connette con easybeach.online, gli indirizzi in notazione URI (Uniform
                Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al
                server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della
                risposta dal server (buon fine, errore, ecc.), il paese di provenienza, le caratteristiche del browser e
                del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad
                esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito
                all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai
                parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>

            <h3>Utente</h3>
            <p>L'individuo che utilizza easybeach.online che, salvo ove diversamente specificato, coincide con
                l'Interessato.</p>

            <h3>Interessato</h3>
            <p>La persona fisica cui si riferiscono i Dati Personali.</p>

            <h3>Responsabile del Trattamento (o Responsabile)</h3>
            <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati
                personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>

            <h3>Titolare del Trattamento (o Titolare)</h3>
            <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o
                insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti
                adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di
                easybeach.online. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di
                easybeach.online.</p>

            <h3>easybeach.online (o questa Applicazione)</h3>
            <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli
                Utenti.</p>

            <h3>Servizio</h3>
            <p>Il Servizio fornito da easybeach.online così come definito nei relativi termini (se presenti) su questo
                sito/applicazione.</p>

            <h3>Unione Europea (o UE)</h3>
            <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si
                intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico
                Europeo.</p>

            <h3>Cookie</h3>
            <p>I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno
                del browser dell'Utente.</p>

            <h3>Strumento di Tracciamento</h3>
            <p>Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web
                beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio
                raccogliendo o salvando informazioni sul dispositivo dell’Utente.</p>

            <h3>Riferimenti legali</h3>
            <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente
                easybeach.online.</p>

            <br/>
            <small>Ultimo aggiornamento:<br/>agosto 2024</small>
        </div>
    );
};

export default CookiePage;
