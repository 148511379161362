import React, { FC, useState, useEffect } from "react";
import {useLocation, useParams} from "react-router-dom";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  const location = useLocation();
  const [transform, setTransform] = useState({ scale: 1, translateX: 0, visible: false });
  const maxScroll = 600;

  const isSpecialRoute = location.pathname.startsWith("/booking") || /\/[^/]+\/[^/]+\/[^/]+/.test(location.pathname);

  const pathSegments = location.pathname.split('/').filter(segment => segment !== '') || [];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop < 200) {
        setTransform({ scale: 2.4, translateX: 33, visible: false });
      } else {
        const scrollFactor = Math.min((scrollTop - 200) / (maxScroll - 200), 1);
        const newScale = 2.4 - scrollFactor * 1.4;
        const newTranslateX = 33 - scrollFactor * 33;
        setTransform({ scale: newScale, translateX: newTranslateX, visible: true });
      }
    };

    if (!isSpecialRoute) {
      window.addEventListener("scroll", handleScroll);
    } else {
      setTransform({ scale: 1, translateX: 0, visible: true });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSpecialRoute]);

  return (
      <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
        <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
          <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
            {transform.visible && (
                <div
                    className="transition-transform duration-300"
                    style={{
                      transform: `scale(${transform.scale}) translateX(${transform.translateX}px)`,
                    }}
                >
                  <Logo />
                </div>
            )}
          </div>

          <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
            {(pathSegments.length <= 2) ? <HeroSearchForm2MobileFactory /> : <div className="container flex items-center justify-center">
              <Logo />
            </div>}
          </div>

          <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden items-center lg:flex space-x-1">
              {/*<Link*/}
              {/*    to="https://easybeach.net"*/}
              {/*    className="*/}
              {/*  text-opacity-90*/}
              {/*  group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"*/}
              {/*>*/}
              {/*  Inserisci la tua struttura*/}
              {/*</Link>*/}

              <div></div>
              {/*<SwitchDarkMode />*/}

              {/*<AvatarDropdown />*/}
            </div>
            <div className="flex items-center space-x-2 lg:hidden">
              {/*<AvatarDropdown />*/}
              {/*<MenuBar />*/}
            </div>
          </div>
        </div>
      </div>
  );
};

export default MainNav2;
