import React, {FC, Fragment, useEffect, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import GuestsInput from "./HeroSearchForm2Mobile/GuestsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SelectOptional from "../containers/MapPage/SelectOptionals";
import SelectItemPage from "../containers/MapPage/SelectItemPage";
import StartRating from "./StartRating/StartRating";
import {Utils} from "custom/index";

import BillDetailWidget from "../containers/MapPage/BillDetailWidget"
import {Alert, Box, CircularProgress} from "@mui/material";

interface ModalSelectOptionalsProps {
  dialogItem?: any;
  setDialogItem?: any;
  confirmSelectItem?: any;
  selectedItem?: any;
  beachResort?: any;
  days?: any;
  setSelectedItem?: any;
}

const ModalSelectItem: React.FC<ModalSelectOptionalsProps> = ({ days, beachResort, dialogItem, setDialogItem, confirmSelectItem, selectedItem, setSelectedItem }) => {
  const [showModal, setShowModal] = useState(false);

  const [optionalsValue, setOptionalsValue] = useState([]);

  useEffect(() => {
    if (selectedItem !== null) {

      let optionalValue = selectedItem.optionals.map((o, i) => {
        return {
          id: o.id,
          quantity: o.default
        }
      })

      setOptionalsValue(optionalValue)
    }
  }, [selectedItem])

  const handleChangeQuantity = (value, type) => {
    let optionalForm = [...optionalsValue]
    optionalForm[optionalForm.findIndex((o) => o.id === type)].quantity = value;
    setOptionalsValue(optionalForm)
  };

  useEffect(() => {
    setShowModal(Boolean(dialogItem));
  }, [dialogItem])

  function closeModal() {
    setSelectedItem(null)
    setDialogItem(() => null);
  }


  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-y-auto flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    {(selectedItem) ? <div>
                      <div className="flex-1 pt-12 py-1 flex flex-col overflow-y-auto">
                        <div className="flex-1 bg-white dark:bg-neutral-900">

                          <div className={'nc-CheckOutPagePageMain'}>
                            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                              <div className="lg:w-3/5 xl:w-2/3 lg:pr-10 ">
                                <SelectOptional beachResort={beachResort} selectedItem={selectedItem} handleChangeQuantity={handleChangeQuantity}/>
                              </div>
                              <br /><br />
                              <BillDetailWidget
                                  beachResort={beachResort}
                                  days={days}
                                  optionalForm={optionalsValue}
                                  selectedItem={selectedItem}
                              />
                            </main>
                          </div>
                        </div>
                      </div>

                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                            type="button"
                            className="underline font-semibold flex-shrink-0"
                            onClick={closeModal}
                        >
                          Annulla
                        </button>
                        <ButtonPrimary
                            sizeClass="px-6 py-3 !rounded-xl"
                            onClick={() => confirmSelectItem(optionalsValue)}
                        >
                          Seleziona Postazione
                        </ButtonPrimary>
                      </div>
                    </div> :  <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100vh',
                          width: '100vw',
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          pointerEvents: 'auto',
                          backgroundColor: '#ffffff'
                        }}
                    >{((selectedItem === null) ?
                        <CircularProgress/> : <Alert severity="error">
                          Purtroppo la postazione selezionata non è più disponibile
                        </Alert>)}</Box>}
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalSelectItem;
