import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ButtonSubmit from "../ButtonSubmit";

const moment = require("moment");

registerLocale("it", it);

export interface StayDatesRangeInputProps {
    className?: string;
    fieldClassName?: string;
    setDates: (dates: { startDate: Date | null, endDate: Date | null }) => void;
    handleSearch: () => void;
    isFormComplete: boolean;
    initialDates: { startDate: Date | null, endDate: Date | null };
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
           setDates,
           handleSearch,
           isFormComplete,
           className = "[ lg:nc-flex-2 ]",
           fieldClassName = "[ nc-hero-field-padding ]",
           initialDates
       }) => {
    const [startDate, setStartDate] = useState<Date | null>(initialDates.startDate);
    const [endDate, setEndDate] = useState<Date | null>(initialDates.endDate);

    useEffect(() => {
        setStartDate(initialDates.startDate);
        setEndDate(initialDates.endDate);
    }, [initialDates]);

    const onChangeDate = (dates: [Date | null, Date | null], close: () => void) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
        setDates({ startDate: start, endDate: end });

        if (start && end) {
            close();
        }
    };

    const renderInput = () => {
        return (
            <>
                <div className="text-neutral-300 dark:text-neutral-400">
                    <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                </div>
                <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("it-IT", {
                month: "short",
                day: "2-digit",
            }) || "Scegli date"}
              {endDate
                  ? " - " +
                  endDate?.toLocaleDateString("it-IT", {
                      month: "short",
                      day: "2-digit",
                  })
                  : ""}
          </span>
                    <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
                </div>
            </>
        );
    };

    return (
        <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
            {({ open, close }) => (
                <>
                    <div
                        className={`flex-1 z-10 flex items-center focus:outline-none ${
                            open ? "nc-hero-field-focused" : ""
                        }`}
                    >
                        <Popover.Button
                            className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
                                open ? "nc-hero-field-focused" : ""
                            }`}
                            onClickCapture={() => document.querySelector("html")?.click()}
                        >
                            {renderInput()}
                            {startDate && open && (
                                <ClearDataButton onClick={() => onChangeDate([null, null], close)} />
                            )}
                        </Popover.Button>

                        {open && (
                            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
                        )}
                    </div>

                    <div className="flex items-center pr-2 xl:pr-4 z-20">
                        <ButtonSubmit
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isFormComplete) {
                                    handleSearch();
                                }
                            }}
                            disabled={!isFormComplete}
                        />
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute right-10 z-10 mt-3 top-full w-screen max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                            <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                                <DatePicker
                                    locale="it"
                                    selected={startDate}
                                    onChange={(dates) => onChangeDate(dates as [Date | null, Date | null], close)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={moment().toDate()}
                                    maxDate={moment().endOf("year").toDate()}
                                    selectsRange
                                    monthsShown={2}
                                    showPopperArrow={false}
                                    inline
                                    renderCustomHeader={(p) => (
                                        <DatePickerCustomHeaderTwoMonth {...p} />
                                    )}
                                    renderDayContents={(day, date) => (
                                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                                    )}
                                />
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default StayDatesRangeInput;
