import React, {FC} from "react";
import StayCard from "components/StayCard/StayCard";
import {Skeleton} from "@mui/material";
import {Utils} from "custom/index";

export interface StayDataType {
    name: string;
    locality: string;
    photos: string[];
    start_price: number | null;
    items_free: number;
    amenities: string[];
    allowed: boolean;
    slug: string
}

export interface SectionGridFilterCardProps {
    className?: string;
    data: StayDataType[];
    searchParams: {
        slugTipology: string;
        slugLocality: string;
        startDate: Date | null;
        endDate: Date | null;
        localityCity: string;
        localityForm: string;
    };
}

const loadingCard = () => {
    return (
        <div
            className='nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow'
            data-nc-id="StayCard"
        >
            <div className="relative w-full">
                <Skeleton variant="rectangular" height="200px" width="100%"/>
            </div>
            <div className={"p-4 space-y-4"}>
                <div className="space-y-2">
                    <Skeleton variant="rectangular" height="20px" width="100%"/>
                    <Skeleton variant="rectangular" height="25px" width="100%"/>
                    <Skeleton variant="rectangular" height="15px" width="100%"/>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                    <Skeleton variant="rectangular" height="15px" width="100%"/>
                </div>
            </div>
        </div>
    );
};

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
           className = "",
           data,
           searchParams,
       }) => {

    return (
        (searchParams === null) ? <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard">
            <div className={`mb-12 lg:mb-16 ${className}`}>
                <Skeleton variant="rectangular" height="50px" width="50%"/><br/>
                <Skeleton variant="rectangular" height="50px" width="40%"/><br/>
                <Skeleton variant="rectangular" height="20px" width="40%"/>
            </div>
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
                {loadingCard()}
            </div>
        </div> : <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard"
        >
            <div className={`mb-12 lg:mb-16 ${className}`}>
                <h2 className="text-4xl">
                    Stabilimenti Balneari a <br/>
                    <span className="font-bold">{searchParams.localityCity}</span>
                </h2>
                <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                    {data.length > 0 ? (
                        <>
                            <strong>{data.length}</strong>
                            {data.length > 1 ? " strutture disponibili" : " struttura disponibile"}
                            <br />
                            {searchParams.startDate && searchParams.endDate && (
                                <strong>
                                    dal {searchParams.startDate.toLocaleDateString("it-IT", {
                                    month: "short",
                                    day: "2-digit",
                                })} al {searchParams.endDate.toLocaleDateString("it-IT", {
                                    month: "short",
                                    day: "2-digit",
                                })}
                                </strong>
                            )}
                        </>
                    ) : (
                        <>
                            <strong>Nessuna struttura disponibile</strong>
                            {searchParams.startDate && searchParams.endDate && (
                                <>
                                    <br />
                                    <span>
                                        nelle date selezionate: dal {searchParams.startDate.toLocaleDateString("it-IT", {
                                        month: "short",
                                        day: "2-digit",
                                    })} al {searchParams.endDate.toLocaleDateString("it-IT", {
                                        month: "short",
                                        day: "2-digit",
                                    })}
                                    </span>
                                </>
                            )}
                        </>
                    )}
            </span>
            </div>

            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {data.map((stay, i) => (
                    <StayCard key={i} data={stay} />
                ))}
            </div>
        </div>
    );
};

export default SectionGridFilterCard;
