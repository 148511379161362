// import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, {useEffect, useState} from "react";
import {FC} from "react";
import {Loading, Utils} from "custom"
import {Box, CircularProgress, Typography, Alert} from "@mui/material";

export interface SelectOptionalProps {
    className?: string;
    selectedItem?: any;
    handleChangeQuantity?: any;
    beachResort?: any;
}

const SelectOptional: FC<SelectOptionalProps> = ({beachResort, selectedItem, className = "", handleChangeQuantity}) => {


    return (
        <div className={`flex flex-col relative ${className}`}>

            <Box>

                <span>
                    Scegli Allestimento
                </span>

                {selectedItem?.optionals.map((optional, i) => {
                    return <NcInputNumber
                        className="w-full p-2"
                        defaultValue={optional.default}
                        icon={optional.icon}
                        onChange={(value) => handleChangeQuantity(value, optional.id)}
                        max={optional.limit_online}
                        label={optional.name.toUpperCase()}
                        price={optional.price}
                    />
                })}
        </Box>
    </div>
    );
};

export default SelectOptional;
