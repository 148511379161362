import _AuthAPI from './AuthAPI';
import _GeoAPI from './GeoAPI';
import _SearchAPI from './SearchAPI';
import _StreamAPI from './StreamAPI';
import _DetailAPI from './DetailAPI';
import _BookingAPI from './BookingAPI';

export const AuthAPI = _AuthAPI;
export const GeoAPI = _GeoAPI;
export const SearchAPI = _SearchAPI;
export const StreamAPI = _StreamAPI;
export const DetailAPI = _DetailAPI;
export const BookingAPI = _BookingAPI;
