import React, {ReactNode, useEffect} from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
// import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
// import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

export interface Structure {
    alloweed?: boolean;
    amenities: string[];
    city: string;
    description_profile?: string;
    items_free?: number;
    lat: number;
    lng: number;
    locality: string;
    name: string;
    slug: string;
    photos: string[];
    start_price?: number;
    genToken?: string;
}
const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  // useEffect(() => {
  //     console.log(structure_detail)
  // }, [structure_detail])

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}?${params.toString()}`, {replace: true});
  };

  const getImageGalleryListing = () => {
    // if (thisPathname?.includes("/stabilimenti-balneari")) {
    //   return listingStayImageGallery;
    // }
    // if (thisPathname?.includes("/listing-car-detail")) {
    //   return listingCarImageGallery;
    // }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }

    return [];
  };

  return (
    <div className="ListingDetailPage">

      <div className="container ListingDetailPage__content">{children}</div>

      {/*/!* OTHER SECTION *!/*/}
      {/*<div className="container py-24 lg:py-32">*/}
      {/*  <div className="relative py-16">*/}
      {/*    <BackgroundSection />*/}
      {/*    <SectionSliderNewCategories*/}
      {/*      heading="Explore by types of stays"*/}
      {/*      subHeading="Explore houses based on 10 types of stays"*/}
      {/*      categoryCardType="card5"*/}
      {/*      itemPerRow={5}*/}
      {/*      sliderStyle="style2"*/}
      {/*      uniqueClassName="ListingDetailPage"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <SectionSubscribe2 className="pt-24 lg:pt-32" />*/}
      {/*</div>*/}

      <div className="container py-24 lg:py-32">
        <SectionBecomeAnAuthor />
      </div>

    </div>
  );
};

export default DetailPagetLayout;
