import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";

import MapPage from "containers/MapPage/MapPage";

import TermsPage from "containers/CheckOutPage/TermsPage";
import PrivacyPage from "containers/CheckOutPage/PrivacyPage";
import CookiePage from "containers/CheckOutPage/CookiePage";

import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import BookingGestPage from "../containers/BookingGestPage/BookingGestPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: Page404 },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  // { path: "/home-2", component: PageHome2 },
  // { path: "/home-3", component: PageHome3 },
  //
  { path: "/listing-stay", component: ListingStayPage },
  // { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },

];

const MyRoutes = () => {

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      {/*<Routes>*/}
      {/*  {pages.map(({ component, path }) => {*/}
      {/*    const Component = component;*/}
      {/*    return <Route key={path} element={<Component />} path={path} />;*/}
      {/*  })}*/}
      {/*  /!*<Route element={<PageError />} />*!/*/}
      {/*</Routes>*/}

      <Routes>
        <Route path="/" element={<PageHome />} />
        {/*<Route path="/profile" element={<AccountPage />} />*/}
        <Route path="/booking/:genToken" element={<MapPage />} />
        <Route path="/booking-gest/:bookingToken" element={<BookingGestPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/cookie-policy" element={<CookiePage />} />
        <Route path="/:tipology/:locality" element={<ListingStayPage />} />
        <Route path="/:tipology/:locality/:structure" element={<ListingStayDetailPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>

      {/*{WIN_WIDTH < 768 && <FooterNav />}*/}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
