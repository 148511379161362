import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header/Header";
import {Link, useLocation, useParams} from "react-router-dom";


const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

    const {genToken, structure, bookingToken, } = useParams();


  useEffect(() => {

  }, [location.pathname]);


  let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";

    if (location.pathname.startsWith("/booking") || location.pathname.startsWith("/terms")) {
        return null;
    }

  return (
    <>
      <Helmet>
        <title>Easy Beach - Prenota il tuo ombrellone con un click!</title>
      </Helmet>
      <Header className={headerClassName} />
      <div ref={anchorRef} className="h-1 absolute invisible"></div>
    </>
  );
};

export default SiteHeader;
