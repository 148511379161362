import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
// import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
// import SectionVideos from "./SectionVideos";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Porto Pino",
    taxonomy: "category",
    count: null,
    thumbnail:
      "https://i.ytimg.com/vi/2jWx_SWJSPo/maxresdefault.jpg",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Pescara",
    taxonomy: "category",
    count: null,
    thumbnail:
      "https://www.hertzpageo.com/img/Italy/Blog%20pages/Come%20raggiungere%20le%20bellissime%20spiagge%20di%20Pescara/Pescara_beaches_blog_2_.jpg",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "Montesilvano",
    taxonomy: "category",
    count: null,
    thumbnail:
      "https://turismo.abruzzo.it/wp-content/uploads/sites/118/costa-montesilvano.jpg",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Fancavilla al Mare",
    taxonomy: "category",
    count: null,
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Francavilla_al_mare_%28CH%29_%2834724878720%29.jpg",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "Minturno",
    taxonomy: "category",
    count: null,
    thumbnail:
        "https://www.visititaly.com/app_tools/crop.aspx?&img=images/data/68570fc3-441f-4bd3-a95a-0fff3d3a46c3.jpg&w=700&h=521",
  }
];


function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        <SectionHowItWork />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
              categories={DEMO_CATS}
              uniqueClassName="PageHome_s1"
          />
        </div>

        <SectionOurFeatures />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

      </div>
    </div>
  );
}

export default PageHome;
