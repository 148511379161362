let detailStructure = function (typology, locality, structure, start_date, end_date, genToken) {
    return new Promise(function (resolve, reject) {

        let url = global.route.detailStructure + "?typology=" + typology + "&locality=" + locality + "&structure=" + structure;

        if (start_date && end_date)
            url += "&start_date=" + start_date + "&end_date=" + end_date

        if (genToken)
            url += "&genToken=" + genToken

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {



                global.checkAuth(responseJson.status);
                console.log('place detail ok', responseJson);
                if (responseJson.status === "PLACE_DETAIL_OK")
                    resolve(responseJson.detail);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

const DetailAPI = {
    detailStructure: detailStructure
};

module.exports = DetailAPI;
