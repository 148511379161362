import React, {FC, useEffect, useState} from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import {Link, useLocation, useParams} from "react-router-dom";
import {Utils} from "custom/index";
import {StreamAPI} from "api/index";

export interface StayDataType {
    name: string;
    locality: string;
    photos: string[];
    start_price: number | null;
    items_free: number;
    amenities: string[];
    allowed: boolean;
    slug: string;
}
export interface StayCardProps {
    className?: string;
    data: StayDataType;
    size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
         size = "default",
         className = "",
         data,
     }) => {
    const {
        name,
        locality,
        photos,
        start_price,
        amenities,
        slug
    } = data;

    const location = useLocation();

    const [renderedImg, setRenderedImg] = useState<any[]>([]);

    useEffect(() => {
        for (let i = 0; i < photos.length; i++) {
            StreamAPI.streamImage(photos[i]).then((url) => {
                setRenderedImg((prevImgs) => [...prevImgs, url]);
            })
        }
        if (photos.length === 0)
            setRenderedImg([null]);
    }, [])

    const renderSliderGallery = (urlDetail) => {

        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${slug}`}
                    ratioClass="aspect-w-4 aspect-h-3"
                    galleryImgs={renderedImg}
                    href={urlDetail}
                />
            </div>
        );
    };

    const renderContent = () => {

        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Spiaggia Attrezzata
                  </span>
                    <div className="flex items-center space-x-2">
                        <h2
                            className={`font-medium capitalize ${
                                size === "default" ? "text-lg" : "text-base"
                            }`}
                        >
                            <span className="line-clamp-1">{name}</span>
                        </h2>
                    </div>
                    {(locality !== "") ? <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        {size === "default" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        <span className="">{locality}</span>
                    </div> : null}
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                    {(start_price) ? <span className="text-base font-semibold">
                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                          a partire da
                        </span>
                          {` `}
                          {Utils.getValueMoneyLabel(start_price)}
                  </span> : null}
                </div>
            </div>
        );
    };

    const newPathname = `${location.pathname}/${slug}`;
    const params = new URLSearchParams(location.search);
    const urlDetail = `${newPathname}?${params.toString()}`;

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            {renderSliderGallery(urlDetail)}
            <Link to={urlDetail}>{renderContent()}</Link>
        </div>
    );
};

export default StayCard;
