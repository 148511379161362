let autocomplete = function (input) {
    return new Promise(function (resolve, reject) {
        fetch(global.route.autocompleteGeo + "?q=" + input, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('autocompleteGeo', responseJson);
                if (responseJson.status === "PLACE_AUTOCOMPLETE_OK")
                    resolve(responseJson.list);
                else
                    reject(responseJson.message);
            })
            .catch((e) => {
                console.error(e.message);
            });
    });
};

const GeoAPI = {
    autocomplete: autocomplete
};

module.exports = GeoAPI;
