import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import moment from "moment";

const HeroSearchForm2Mobile = () => {
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const startDateParam = searchParams.get("start_date");
  const endDateParam = searchParams.get("end_date");
  const queryLocality = searchParams.get("q");

  const [locationValue, setLocationValue] = useState<{ name: string; province?: string; slug?: string }>({
    name: queryLocality || "",
  });

  const [slugStructure, setSlugStructure] = useState(null);

  const [dates, setDates] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: startDateParam ? new Date(startDateParam) : null,
    endDate: endDateParam ? new Date(endDateParam) : null,
  });

  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);



  useEffect(() => {
    const url = window.location.href;
    const urlParts = url.split("/");

    let slug;
    let structure;

    structure = urlParts[5]?.split("?")[0];
    slug = urlParts[4]?.replace(/-/g, ' ').toUpperCase();

    if (queryLocality) {
      setLocationValue({ name: queryLocality });
      if (structure)
        setSlugStructure(structure)
    } else {

      if (slug)
        setLocationValue({ name: slug });

      if (structure)
        setSlugStructure(structure)
    }


    if (startDateParam && endDateParam) {
      setDates({
        startDate: new Date(startDateParam),
        endDate: new Date(endDateParam),
      });
    }
  }, [queryLocality, startDateParam, endDateParam]);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    const { name, province } = locationValue;
    const { startDate, endDate } = dates;
    const locationText = name && province ? `${name} ${province}` : name;
    const dateText =
        startDate && endDate
            ? `dal ${moment(startDate).format("DD/MM/YYYY")} al ${moment(endDate).format("DD/MM/YYYY")}`
            : "Seleziona le date";

    return (
        <button
            onClick={openModal}
            id="open-data-modal"
            className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
        >
          <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" />
          <div className="ml-3 flex-1 text-left overflow-hidden">
            <span className="block font-medium text-sm">{locationText || name || "Dove andiamo?"}</span>
            <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">
              {locationText ? dateText : "Località • Data"}
            </span>
            </div>
          </div>
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
              viewBox="0 0 16 16"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className="block w-4 h-4"
              fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
        </button>
    );
  };

  const handleSubmit = () => {
    let url = `/stabilimenti-balneari/${locationValue.slug || locationValue.name}`;

    if (slugStructure && queryLocality === slugStructure) {
      url = url + "/" + slugStructure
    }

    if (dates.startDate && dates.endDate) {
      url += `?start_date=${moment(dates.startDate).format("YYYY-MM-DD")}&end_date=${moment(dates.endDate).format("YYYY-MM-DD")}`;

      if (locationValue.slug || queryLocality)
        url += `&q=${locationValue.slug || queryLocality}`
    }

    navigate(url);
    closeModal();
  };

  const handleReset = () => {
    setLocationValue({ name: "" });
    setDates({ startDate: null, endDate: null });
    navigate("/");
  };

  return (
      <div className="HeroSearchForm2Mobile">
        {renderButtonOpenModal()}
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
              as="div"
              className="HeroSearchFormMobile__Dialog relative z-max"
              onClose={closeModal}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out transition-transform"
                    enterFrom="opacity-0 translate-y-52"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in transition-transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                    {showDialog && (
                        <Tab.Group manual>
                          <div className="absolute left-4 top-4">
                            <button className="" onClick={closeModal}>
                              <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                            </button>
                          </div>
                          <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                            {["Spiagge", "Esperienze", "Noleggio Barche"].map(
                                (item, index) => (
                                    <Tab key={index} as={Fragment} disabled={item !== "Spiagge"}>
                                      {({ selected }) => (
                                          <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                            <div className={`${selected ? "text-black dark:text-white" : ""}`}>
                                              {item !== "Spiagge" ? (
                                                  <span style={{ opacity: 0.5 }}>
                                        {item}
                                                    <br />
                                              <small className="text-red-500">Coming Soon</small>
                                            </span>
                                              ) : (
                                                  item
                                              )}
                                            </div>
                                            {selected && (
                                                <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                            )}
                                          </div>
                                      )}
                                    </Tab>
                                )
                            )}
                          </Tab.List>
                          <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                            <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                              <Tab.Panel>
                                <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                                  <StaySearchForm
                                      location={locationValue}
                                      dates={dates}
                                      setLocation={setLocationValue}
                                      setDates={setDates}
                                  />
                                </div>
                              </Tab.Panel>
                            </Tab.Panels>
                          </div>
                          <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                            <button
                                type="button"
                                className="underline font-semibold flex-shrink-0"
                                onClick={handleReset}
                            >
                              Reset
                            </button>
                            <ButtonSubmit
                                onClick={handleSubmit}
                                disabled={!locationValue.name}
                            />
                          </div>
                        </Tab.Group>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
  );
};

export default HeroSearchForm2Mobile;
